<template>
  <CDropdown variant="nav-item">
    <template v-if="user">
      <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
        {{ user.fullname }}&nbsp;&nbsp;
        <CAvatar v-if="user.avatar" :src="user.avatar['100x']" size="md" />
      </CDropdownToggle>
      <CDropdownMenu class="pt-0">
        <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
          {{ user.fullname }}
        </CDropdownHeader>
        <CDropdownItem @click="logout"> <CIcon icon="cil-lock-locked" /> Logout </CDropdownItem>
      </CDropdownMenu>
    </template>
  </CDropdown>
</template>

<script>
import {TokenService} from "@/services/token.service";
export default {
  name: 'AppHeaderDropdownAccnt',
  data() {
    return {
      itemsCount: 42,
      user: TokenService.getUser()
    }
  },
  methods: {
    logout() {
      TokenService.clearUser()
      TokenService.removeToken()
      window.location.href = '/login'
    }
  }
}
</script>
