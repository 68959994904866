<template>
  <div v-if="report" class="animated fadeIn">
    <CRow>
      <CCol>
        <div class="float-end">
          <CButtonGroup class="fixed shadow">
            <CButton :color="report.status" class="font-weight-bold">
              <i class="fa fa-exclamation"></i>&nbsp;
              {{report.status_crm}}
            </CButton>
            <CButton v-if="report.report_data" color="primary" class="text-white font-weight-bold">
              Балл {{ report.report_data.points }}
            </CButton>
          </CButtonGroup>
        </div>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <router-link :to="'/company/' + id + '?tab=6'">←	 Назад к диагностике компании &laquo;<small>{{company.name}}</small>&raquo;</router-link>
          </CCardHeader>
          <form @submit.prevent="saveConfirm({rawData: rawData, isSend: true})" class="form-result">
            <h4>{{ formCategory.title }}</h4>
            <hr/>
            <div v-for="(form,key) in formCategory.forms" :key="key">
              <h5>{{key + 1}}. {{ form.title }}</h5>
              <div v-for="(question,qKey) in form.form_questions" :key="qKey" :disabled="report.is_send">
                <label class="title" :for="qKey"><strong>{{qKey + 1}}. {{ question.title }}</strong></label>
                <div v-if="question.answers.length && question.id !== anketaKey">
                  <div class="block-form-control-wrapper">
                    <div class="form-wrapper">
                      <div v-if="question.answers.length" v-for="(answer, kAnswer) in question.answers" :key="kAnswer" class="radio">
                        <label :for="answer.id" class="for-radio">
                          <input type="radio" :id="answer.id" v-model="rawData.items[question.id]" required :value="answer.title" :name="question.id" class="ch-settings">
                          &nbsp;
                          <span class="text" v-html="answer.title"></span>
                        </label>
                      </div>
                    </div>
                    <br/>
                    <p class="text-blue"><i>{{question.description}}</i></p>
                  </div>
                </div>
              </div>
            </div>
            <div slot="footer">
              <CButtonGroup>
                <router-link :to="'/company/' + id + '?tab=6'" class="btn btn-sm btn-outline">
                  <i class="fa fa-arrow-circle-left"></i>
                  Назад
                </router-link>
              </CButtonGroup>
              <CButtonGroup class="float-end">
                <button v-if="report.is_send" @click="print" type="button" class="btn btn-primary btn-sm text-white float-end">
                  <i class="fa fa-print"></i>
                  Распечатать
                </button>
                <button v-if="!report.is_send" @click="updateReport({rawData: rawData})" type="button" class="btn btn-secondary btn-sm">
                  <i class="fa fa-save"></i>
                  В черновик
                </button>
                <button v-if="!report.is_send" type="submit" class="btn btn-success btn-sm">
                  <i class="fa fa-dot-circle-o"></i>
                  Сохранить
                </button>
              </CButtonGroup>
            </div>
          </form>
        </CCard>
      </CCol>
    </CRow>
    <div id="print">
      <div class="printer">
        <h1>Структурированное интервью с представителями компании</h1>
        <h1>{{ company.name }} (ОГРН {{ company.ogrn }})</h1>
        <h4>{{ formCategory.title }}</h4>
        <div v-for="(form,key) in formCategory.forms" :key="key">
          <h5>{{key + 1}}. {{ form.title }}</h5>
          <div v-for="(question,qKey) in form.form_questions" :key="qKey" :disabled="report.is_send">
            <p><strong>{{ question.title }}</strong></p>
            <p>{{ rawData.items[question.id] }}</p>
          </div>
          <hr/>
        </div>
        <br/>
        Ф.И.О: {{user.fullname}}
      </div>
    </div>
  </div>
</template>

<script>
    import {TokenService} from "@/services/token.service";
    import ApiService from "@/services/api.service";
    export default {
        name: "DiagnostInterview",
        mounted() {
            this.id = this.$route.params.id;
            ApiService
                .get('api/mentoring/enrolls/' + this.id + '/company')
                .then((res) => {
                    this.company = res.data;
                    let variant = '';
                    this.report = this.company.enroll.reports.interview_report;
                    if(this.company.enroll.program && this.company.enroll.program.id === 2) {
                      this.anketaKey = 3866
                    }
                    if(this.report.raw_data) {
                        this.rawData = this.report.raw_data;
                    }

                    if((this.company.enroll.program && this.company.enroll.program.id === 2)) {
                      variant = 'variant3';
                    } else if(['Категория 1', 'Категория 2'].indexOf(this.company.enroll.form_result.result.category) > -1) {
                        variant = 'variant1';
                    } else if(['Категория 3'].indexOf(this.company.enroll.form_result.result.category) > -1) {
                        variant = 'variant2';
                    }
                    ApiService
                        .get('api/formcategories/' + variant)
                        .then((res) => {
                            this.formCategory = res.data;
                            this.rawData.category = res.data.id;
                        });
                })
            ;
        },
        data() {
            return {
              formCategory: {},
              user: TokenService.getUser(),
              rawData: {
                  items: {},
                  category: null
              },
              company: {},
              report: null,
              id: null,
              anketaKey: 128
            }
        },
        methods: {
            updateReport(params, message = true) {
                return ApiService
                    .put('api/mentoring/enrollreports/' + this.report.id, params)
                    .then(res => {
                        this.report = res.data;
                        if(this.report.raw_data) {
                            this.rawData = this.report.raw_data;
                        }
                        if(message) {
                            this.$toast.show('Изменения сохранены', {type: 'success'});
                        }
                    })
            },
            saveConfirm(params) {
              this.updateReport(params);
                /*this.$bvModal.msgBoxConfirm('Пожалуйста, убедитесь, что вы верно заполнили форму структурированного интервью. При нажатии Ок произойдет завершение работ с формой и окончательное сохранение этапа диагностики.', {
                    title: 'Подтвердите действие:',
                    okVariant: 'danger',
                    okTitle: 'Да',
                    cancelTitle: 'Нет',
                    hideHeaderClose: false,
                    centered: true
                }).then(res => {
                    if(res) {

                    }
                })*/
            },
            print() {
                let block = window.open('', 'PRINT');
                block.document.write('<html><head>');
                block.document.write('<style>.printer {margin: 20px; font-family: Arial; font-size: 11pt;} h1 {font-size: 12pt; font-family: Arial;}</style>');
                block.document.write('</head><body class="receipt">');
                block.document.write(document.getElementById('print').innerHTML);
                block.document.write('</body></html>');
                block.document.close();
                block.focus();
                block.print();
                block.close();
                return true;
            }
        }
    }
</script>

<style scoped>
  #print {
    display: none;
  }
  input:checked + .text {
    text-decoration: underline;
    font-weight: bold;
    color: #323232;
  }
  .fixed {
    position: fixed;
    right: 50px;
    top: 130px;
    z-index: 1020;
    border: 1px solid #c8ced3;
  }
  .fixed > .btn {
    border-radius: 0;
  }
</style>
