<template>
  <div>
    <vue3-progress-bar></vue3-progress-bar>
    <router-view />
  </div>
</template>
<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
@import '~@marcoschulte/vue3-progress/dist/index.css';
.btn {
  font-weight: bold;
  color: #fff;
}
.card.bg-info {
  border-color: #2eadd3;
}
.bg-info {
  background-color: #63c2de!important;
}
.card.bg-warning {
  border-color: #c69500;
}
.bg-warning {
  background-color: #ffc107!important;
}
.card.bg-success {
  border-color: #379457;
}
.bg-success {
  background-color: #4dbd74!important;
}
.card {
  min-height: 120px!important;
}
.bg-primary {
  background-color: #20a8d8!important;
}
.bg-primary {
  background-color: #20a8d8!important;
}
.form-result {
  padding: 20px;
}
.small {
  font-size: 12px;
  text-transform: lowercase;
}
</style>
