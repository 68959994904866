<template>
  <div v-if="company">
    <div v-if="print">
      <p><b><span style="font-size:11.0pt"><span style="font-family:Arial">2.&nbsp; Сведения о наличии либо отсутствии выявленных стоп-факторов для осуществления экспорта продукта в намеченные страны</span></span></b></p>
      <table class="MsoTableGrid" style="width:453.35pt; border-collapse:collapse; border:solid windowtext 1.0pt">
        <tbody>
        <tr>
          <td style="width:21.3pt; border:solid windowtext 1.0pt" width="21">
            <p align="center" style="text-align:center; padding:0cm 5.4pt 0cm 5.4pt">&nbsp;</p>
          </td>
          <td style="width:141.05pt; border:solid windowtext 1.0pt; border-left:none" width="141">
            <p align="center" style="text-align:center; padding:0cm 5.4pt 0cm 5.4pt"><b><span style="font-family:Arial;font-size:11.0pt"><span style="font-family:Arial">Страна/Товар</span></span></b></p>
          </td>
          <td style="width:155.35pt; border:solid windowtext 1.0pt; border-left:none" width="155">
            <p align="center" style="text-align:center; padding:0cm 5.4pt 0cm 5.4pt"><b><span style="font-size:11.0pt"><span style="font-family:Arial">Выявленные барьеры</span></span></b></p>
          </td>
          <td style="width:135.65pt; border:solid windowtext 1.0pt; border-left:none" width="136">
            <p align="center" style="text-align:center; padding:0cm 5.4pt 0cm 5.4pt"><b><span style="font-size:11.0pt"><span style="font-family:Arial">Количество</span></span></b></p>
          </td>
        </tr>
        <template v-for="(factor, key) in factors"  :key="key">
          <tr>
            <td rowspan="3" style="width:21.3pt; border:solid windowtext 1.0pt; border-top:none" width="21">
              <p style="padding:0cm 5.4pt 0cm 5.4pt"><span style="font-size:11.0pt;"><span style="font-family:Arial">{{ key + 1 }}.</span></span></p>
            </td>
            <td rowspan="3" style="width:155.35pt; border-top:none; border-left:none; border-bottom:solid windowtext 1.0pt; border-right:solid windowtext 1.0pt">
              <p style="padding:0cm 5.4pt 0cm 5.4pt"><span style="font-size:11.0pt;font-family:Arial;">{{factor.country.originalObject.country_name_ru}}</span></p>
            </td>
            <td style="width:155.35pt; border-top:none; border-left:none; border-bottom:solid windowtext 1.0pt; border-right:solid windowtext 1.0pt" width="155">
              <p style="padding:0cm 5.4pt 0cm 5.4pt"><span style="font-size:11.0pt;font-family:Arial;">Критичные</span></p>
            </td>
            <td style="width:135.65pt; border-top:none; border-left:none; border-bottom:solid windowtext 1.0pt; border-right:solid windowtext 1.0pt" width="155">
              <p style="padding:0cm 5.4pt 0cm 5.4pt"><span style="font-size:11.0pt;font-family:Arial;">{{calcBar(factor).red}}</span></p>
            </td>
          </tr>
          <tr>
            <td style="width:155.35pt; border-top:none; border-left:none; border-bottom:solid windowtext 1.0pt; border-right:solid windowtext 1.0pt" width="155">
              <p style="padding:0cm 5.4pt 0cm 5.4pt"><span style="font-size:11.0pt;font-family:Arial;">Могут вызывать сложности</span></p>
            </td>
            <td style="width:135.65pt; border-top:none; border-left:none; border-bottom:solid windowtext 1.0pt; border-right:solid windowtext 1.0pt" width="155">
              <p style="padding:0cm 5.4pt 0cm 5.4pt"><span style="font-size:11.0pt;font-family:Arial;">{{calcBar(factor).yellow}}</span></p>
            </td>
          </tr>
          <tr>
            <td style="width:155.35pt; border-top:none; border-left:none; border-bottom:solid windowtext 1.0pt; border-right:solid windowtext 1.0pt" width="155">
              <p style="padding:0cm 5.4pt 0cm 5.4pt"><span style="font-size:11.0pt;font-family:Arial;">Некритичные</span></p>
            </td>
            <td style="width:135.65pt; border-top:none; border-left:none; border-bottom:solid windowtext 1.0pt; border-right:solid windowtext 1.0pt" width="155">
              <p style="padding:0cm 5.4pt 0cm 5.4pt"><span style="font-size:11.0pt;font-family:Arial;">{{calcBar(factor).green}}</span></p>
            </td>
          </tr>
        </template>
        <tr style="height:31.25pt">
          <td colspan="2" style="width:162.35pt; border:solid windowtext 1.0pt; border-top:none" width="162">
            <p align="right" style="text-align:right; padding:0cm 5.4pt 0cm 5.4pt">
              <span style="height:31.25pt"><b><span style="font-size:11.0pt;font-family:Arial;">СТАТУС</span></b></span>
            </p>
          </td>
          <td colspan="2" style="width:291.0pt; border-top:none; border-left:none; border-bottom:solid windowtext 1.0pt; border-right:solid windowtext 1.0pt" width="291">
            <p style="padding:0cm 5.4pt 0cm 5.4pt"><span style="font-size:11.0pt;font-family:Arial;">{{report.status_crm}}</span></p>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <h5>2. Сведения о наличии либо отсутствии выявленных стоп-факторов для осуществления экспорта продукта в намеченные страны:</h5>
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th class="align-middle text-center">№</th>
          <th class="align-middle">
            Страна/Товар
          </th>
          <th class="align-middle">
            Выявленные барьеры
          </th>
          <th class="align-middle">
            Количество
          </th>
        </tr>
        </thead>
        <tbody>
        <template v-for="(factor, key) in factors" :key="key">
          <tr>
            <td class="align-middle text-center" rowspan="3">
              {{ key + 1 }}.
            </td>
            <td class="align-middle" rowspan="3">
              <p>{{factor.country.originalObject.country_name_ru}}</p>
            </td>
            <td class="align-middle">
              Критичные
            </td>
            <td class="align-middle">
              <p>{{calcBar(factor).red}}</p>
            </td>
          </tr>
          <tr>
            <td class="align-middle">
              Могут вызывать сложности
            </td>
            <td class="align-middle">
              <p>{{calcBar(factor).yellow}}</p>
            </td>
          </tr>
          <tr>
            <td class="align-middle">
              Некритичные
            </td>
            <td class="align-middle">
              <p>{{calcBar(factor).green}}</p>
            </td>
          </tr>
        </template>
        <tr>
          <td class="align-middle" colspan="2">
            <b><span>СТАТУС</span></b>
          </td>
          <td class="align-middle font-weight-bold" :class="'bg-'+report.status" colspan="2">
            {{report.status_crm}}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
    export default {
        name: "FactorsReport",
        props: ['company', 'print'],
        data: () => {
            return {
                factors: {},
                report: {},
                colorsText: {
                    red: 'Критичный фактор',
                    yellow: 'Фактор может вызвать сложности',
                    green: 'Фактор не является критичным',
                },
                statusText: {
                    'success': 'Выявлены некритичные барьеры / не выявлены барьеры',
                    'warning': 'Выявлены барьеры, которые могут вызвать сложности при экспорте',
                    'danger': 'Выявлены критичные заградительные барьеры или более 5, которые могут вызвать сложности при экспорте',
                }
            }
        },
        mounted() {
            this.report = this.company.enroll.reports.stop_factors_report;
            this.factors = this.company.enroll.reports.stop_factors_report ? this.company.enroll.reports.stop_factors_report.report_data : [];
            let forms = [];
            this.factors.forEach((factor) => {
                if(!factor.bars) {
                    factor.bars = [];
                    let i = 0;
                    while (i < 3) {
                        factor.bars.push({name: '', status: ''});
                        i++;
                    }
                }
                forms.push(factor);
            })
            this.factors = forms;
            this.factors = this.cloneObj(this.factors)
        },
        computed: {
            colors: function() {
                let colorsArray = [];
                this.factors.forEach((factor) => {
                    let colors = {red: 0, yellow: 0, green: 0, isFailed: false, status: 'success', statusText: this.statusText['success'], isBlock: false}
                    factor.bars.forEach((bar) => {
                        if (bar.status === 'red') {
                            colors.red++;
                        }
                        if (bar.status === 'yellow') {
                            colors.yellow++;
                        }
                        if (bar.green === 'green') {
                            colors.green++;
                        }
                    });
                    colors.isBlock = factor.isBlock;
                    colors.isFailed = colors.yellow >= 5 || colors.red > 0;

                    colors.status = colors.yellow >= 1 && colors.yellow < 5 && !colors.red ? 'warning' : 'success';
                    colors.status = colors.isFailed ? 'danger' : colors.status;

                    colors.statusText = this.statusText[colors.status];

                    colorsArray.push(colors);
                });
                return colorsArray;
            },
            hasFailed: function() {
                return this.colors.filter(color => color.isFailed && !color.isBlock).length > 0;
            },
            hasSuccess: function() {
                return this.colors.filter(color => color.status === 'success').length > 0;
            },
            hasWarning: function() {
                return this.colors.filter(color => color.status === 'warning').length > 0;
            }
        },
        methods: {
            calcBar(factor) {
                return {
                    red: factor.bars.filter(bar => bar.status === 'red').length,
                    yellow: factor.bars.filter(bar => bar.status === 'yellow').length,
                    green: factor.bars.filter(bar => bar.status === 'green').length
                }
            },
            cloneObj(src) {
                return JSON.parse(JSON.stringify(src));
            },
        }
    }
</script>

<style scoped>

</style>
