export default [
  {
    component: 'CNavItem',
    name: 'Главная',
    to: '/dashboard',
    icon: 'cil-speedometer',
  },
  {
    component: 'CNavTitle',
    name: 'Этапы оказания услуги'
  },
  {
    component: 'CNavItem',
    name: 'Верификация и отбор компаний',
    to: '/dashboard?status=verify',
  },
  {
    component: 'CNavItem',
    name: 'Аналитический отчет',
    to: '/dashboard?status=anal',
  },
  {
    component: 'CNavItem',
    name: 'Комплексная дорожная карта',
    to: '/dashboard?status=complex',
  },
  {
    component: 'CNavItem',
    name: 'Сопровождение',
    to: '/dashboard?status=escort',
  },
  {
    component: 'CNavItem',
    name: 'Мониторинг',
    to: '/dashboard?status=monitoring',
  },
  {
    component: 'CNavItem',
    name: 'Оказание услуг завершено.',
    to: '/dashboard?status=finish',
  },
  {
    component: 'CNavTitle',
    name: 'Отчеты',
    isHead: true
  },
  {
    component: 'CNavItem',
    name: 'Сводный отчет',
    to: '/report',
    isHead: true
  }

  // {
  //   component: 'CNavItem',
  //   name: 'Download CoreUI',
  //   href: 'http://coreui.io/vue/',
  //   icon: { name: 'cil-cloud-download', class: 'text-white' },
  //   _class: 'bg-success text-white',
  //   target: '_blank'
  // },
  // {
  //   component: 'CNavItem',
  //   name: 'Try CoreUI PRO',
  //   href: 'http://coreui.io/pro/vue/',
  //   icon: { name: 'cil-layers', class: 'text-white' },
  //   _class: 'bg-danger text-white',
  //   target: '_blank'
  // }
]
