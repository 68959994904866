const TOKEN_KEY = "mentor.token";
const USER_KEY = "mentor.user";
const REFRESH_TOKEN_KEY = "mentor.refresh_token";
const ROLE_CRM_MENTORING_MENTOR = "ROLE_CRM_MENTORING_MENTOR";

const TokenService = {
	getToken() {
		return localStorage.getItem(TOKEN_KEY);
	},
	setToken(accessToken) {
		localStorage.setItem(TOKEN_KEY, accessToken);
	},
	getHeaders() {
		return {
			Authorization: `Bearer ${this.getToken()}`
		}
	},
	getUser() {
		return JSON.parse(localStorage.getItem(USER_KEY));
	},
  isMentor() {
    if (this.getUser()) {
      return this.getUser().roles.indexOf(ROLE_CRM_MENTORING_MENTOR) > -1
    }
    return false;
  },
	clearUser() {
		return localStorage.removeItem(USER_KEY);
	},
  signOut() {
    this.clearUser()
    this.removeToken()
    this.removeRefreshToken()
  },
	setUser(user) {
		localStorage.setItem(USER_KEY, JSON.stringify(user));
	},
	removeToken() {
		localStorage.removeItem(TOKEN_KEY);
	},
	getRefreshToken() {
		return localStorage.getItem(REFRESH_TOKEN_KEY);
	},
	setRefreshToken(refreshToken) {
		localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
	},
	removeRefreshToken() {
		localStorage.removeItem(REFRESH_TOKEN_KEY);
	}
};

export { TokenService };
