<template>
  <div v-if="company">
    <div v-if="print">
      <p><b><span style="font-size:11.0pt"><span style="font-family:Arial">1. Оценка экспортной зрелости</span></span></b></p>
      <table class="MsoTableGrid" style="border-collapse:collapse; border:solid windowtext 1.0pt">
        <tbody>
        <tr style="height:22.85pt">
          <td style="width:35.2pt; border:solid windowtext 1.0pt" width="35">
            <p align="center" style="text-align:center; padding:0cm 5.4pt 0cm 5.4pt"><span style="height:22.85pt"><b><span style="font-size:11.0pt"><span style="font-family:Arial">№№</span></span></b></span></p>
          </td>
          <td style="width:276.25pt; border:solid windowtext 1.0pt; border-left:none" width="276">
            <p align="center" style="text-align:center; padding:0cm 5.4pt 0cm 5.4pt"><span style="height:22.85pt"><b><span style="font-size:11.0pt"><span style="font-family:Arial">Параметр</span></span></b></span></p>
          </td>
          <td style="width:155.75pt; border:solid windowtext 1.0pt; border-left:none" width="156">
            <p align="center" style="text-align:center; padding:0cm 5.4pt 0cm 5.4pt"><span style="height:22.85pt"><b><span style="font-size:11.0pt"><span style="font-family:Arial">Показатель</span></span></b></span></p>
          </td>
        </tr>
        <tr>
          <td style="width:35.2pt; border:solid windowtext 1.0pt; border-top:none; font-size:11.0pt;font-family:Arial;" width="35">
            <ol style="font-size:11.0pt;font-family:Arial;">
              <li style="padding:0cm 5.4pt 0cm 5.4pt;font-size:11.0pt;font-family:Arial;">&nbsp;</li>
            </ol>
          </td>
          <td style="width:276.25pt; border-top:none; border-left:none; border-bottom:solid windowtext 1.0pt; border-right:solid windowtext 1.0pt" width="276">
            <p style="padding:0cm 5.4pt 0cm 5.4pt"><span style="font-size:11.0pt"><span style="font-family:Arial">Анкеты экспортной зрелости (баллы)</span></span></p>

            <p style="padding:0cm 5.4pt 0cm 5.4pt">&nbsp;</p>
          </td>
          <td style="width:155.75pt; border-top:none; border-left:none; border-bottom:solid windowtext 1.0pt; border-right:solid windowtext 1.0pt" width="156">
            <p align="center" style="text-align:center; padding:0cm 5.4pt 0cm 5.4pt"><span style="font-family:Arial; font-size:11.0pt;">{{company.enroll.form_result.result.points}}</span></p>
          </td>
        </tr>
        <tr>
          <td style="width:35.2pt; border:solid windowtext 1.0pt; border-top:none" width="35">
            <ol start="2">
              <li style="padding:0cm 5.4pt 0cm 5.4pt;font-size:11.0pt;font-family:Arial;">&nbsp;</li>
            </ol>
          </td>
          <td style="width:276.25pt; border-top:none; border-left:none; border-bottom:solid windowtext 1.0pt; border-right:solid windowtext 1.0pt" width="276">
            <p style="padding:0cm 5.4pt 0cm 5.4pt"><span style="font-size:11.0pt"><span style="font-family:Arial">Анкета экспортной зрелости по итогам верификации (баллы)</span></span></p>
            <p style="padding:0cm 5.4pt 0cm 5.4pt">&nbsp;</p>
          </td>
          <td style="width:155.75pt; border-top:none; border-left:none; border-bottom:solid windowtext 1.0pt; border-right:solid windowtext 1.0pt" width="156">
            <p align="center" style="text-align:center; padding:0cm 5.4pt 0cm 5.4pt">
              <span style="font-size:11.0pt"><span style="font-family:Arial; font-size:11.0pt;" v-if="company.enroll.reports.verify_form_report && company.enroll.reports.verify_form_report.report_data">{{company.enroll.reports.verify_form_report.report_data.points}}</span></span>
            </p>
          </td>
        </tr>
        <tr>
          <td style="width:35.2pt; border:solid windowtext 1.0pt; border-top:none" width="35">
            <ol start="3">
              <li style="padding:0cm 5.4pt 0cm 5.4pt;font-size:11.0pt;font-family:Arial;">&nbsp;</li>
            </ol>
          </td>
          <td style="width:276.25pt; border-top:none; border-left:none; border-bottom:solid windowtext 1.0pt; border-right:solid windowtext 1.0pt" width="276">
            <p style="padding:0cm 5.4pt 0cm 5.4pt"><span style="font-size:11.0pt"><span style="font-family:Arial; font-size:11.0pt;">Категория компании по итогам прохождения анкеты экспортной зрелости</span></span></p>
          </td>
          <td style="width:155.75pt; border-top:none; border-left:none; border-bottom:solid windowtext 1.0pt; border-right:solid windowtext 1.0pt" width="156">
            <p align="center" style="text-align:center; padding:0cm 5.4pt 0cm 5.4pt"><span style="font-size:11.0pt"><span style="font-family:Arial; font-size:11.0pt;">{{company.enroll.form_result.result.category}}</span></span></p>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <h5>1.	Оценка экспортной зрелости:</h5>
        <table class="table table-striped table-bordered">
          <thead>
          <tr>
            <th>Параметр</th>
            <th>Показатель</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              Анкета экспортной зрелости (баллы):
            </td>
            <td class="bg-primary font-weight-bold">
              {{company.enroll.form_result.result.points}}
            </td>
          </tr>
          <tr>
            <td>
              Анкета экспортной зрелости по итогам верификации (баллы):
            </td>
            <td class="bg-primary font-weight-bold">
              <span v-if="company.enroll.reports.verify_form_report && company.enroll.reports.verify_form_report.report_data">{{company.enroll.reports.verify_form_report.report_data.points}}</span>
            </td>
          </tr>
          <tr>
            <td>
              Категория компании по итогам прохождения анкеты экспортной зрелости:
            </td>
            <td class="bg-warning font-weight-bold">
              {{company.enroll.form_result.result.category}}
            </td>
          </tr>
          </tbody>
        </table>
    </div>
  </div>
</template>

<script>
    export default {
        name: "FormReport",
        props: ['company', 'print']
    }
</script>

<style scoped>

</style>
