<template>
  <div v-if="report && report.report_data" id="print-report">
    <p v-if="!title" style="margin-bottom: 0.0001pt;"><span style="line-height:normal"><b><span style="font-size:12.0pt"><span style="font-family:Arial">Отчет &laquo;Экспресс-диагностика финансового положения компании&raquo;</span></span></b></span></p>
    <p v-else style="margin-bottom: 0.0001pt;"><span style="line-height:normal"><b><span style="font-size:12.0pt"><span style="font-family:Arial">Отчет &laquo;{{ title }}&raquo;</span></span></b></span></p>

    <p style="margin-bottom:.0001pt"><span style="line-height:normal"><b><span style="font-family:Arial">Наименование компании: </span></b><span style="font-family:Arial">{{company.name}} (ОГРН: {{company.ogrn}})</span></span></p>

    <p style="margin-bottom:.0001pt">&nbsp;</p>

    <table class="MsoTableGrid" style="width:509.9pt; border-collapse:collapse; border:solid windowtext 1.0pt" width="510">
      <tbody>
      <tr style="height:24.6pt">
        <td style="width:35.35pt; border:solid windowtext 1.0pt" width="35">
          <p align="center" style="margin-bottom:.0001pt; text-align:center; padding:0cm 5.4pt 0cm 5.4pt"><span style="line-height:normal"><span style="height:24.6pt"><b><span style="font-family:Arial">№№</span></b></span></span></p>
        </td>
        <td style="width:184.15pt; border:solid windowtext 1.0pt; border-left:none" width="184">
          <p align="center" style="margin-bottom:.0001pt; text-align:center; padding:0cm 5.4pt 0cm 5.4pt"><span style="line-height:normal"><span style="height:24.6pt"><b><span style="font-family:Arial">Показатель</span></b></span></span></p>
        </td>
        <td style="width:77.95pt; border:solid windowtext 1.0pt; border-left:none" width="78">
          <p align="center" style="margin-bottom:.0001pt; text-align:center; padding:0cm 5.4pt 0cm 5.4pt"><span style="line-height:normal"><span style="height:24.6pt"><b><span style="font-family:Arial">{{currentYear - 4}}</span></b></span></span></p>
        </td>
        <td style="width:77.95pt; border:solid windowtext 1.0pt; border-left:none" width="78">
          <p align="center" style="margin-bottom:.0001pt; text-align:center; padding:0cm 5.4pt 0cm 5.4pt"><span style="line-height:normal"><span style="height:24.6pt"><b><span style="font-family:Arial">{{currentYear - 3}}</span></b></span></span></p>
        </td>
        <td style="width:77.95pt; border:solid windowtext 1.0pt; border-left:none" width="78">
          <p align="center" style="margin-bottom:.0001pt; text-align:center; padding:0cm 5.4pt 0cm 5.4pt"><span style="line-height:normal"><span style="height:24.6pt"><b><span style="font-family:Arial">{{currentYear - 2}}</span></b></span></span></p>
        </td>
        <td style="width:77.95pt; border:solid windowtext 1.0pt; border-left:none" width="78">
          <p align="center" style="margin-bottom:.0001pt; text-align:center; padding:0cm 5.4pt 0cm 5.4pt"><span style="line-height:normal"><span style="height:24.6pt"><b><span style="font-family:Arial">{{currentYear - 1}}</span></b></span></span></p>
        </td>
        <td style="width:56.55pt; border:solid windowtext 1.0pt; border-left:none" width="57">
          <p align="center" style="margin-bottom:.0001pt; text-align:center; padding:0cm 5.4pt 0cm 5.4pt"><span style="line-height:normal"><span style="height:24.6pt"><b><span style="font-family:Arial">Баллы</span></b></span></span></p>
        </td>
      </tr>
      <tr v-for="(param, key) in report.report_data.params" :key="key">
        <td style="width:35.35pt; border:solid windowtext 1.0pt; border-top:none; text-align: center;" width="35">
          {{key + 1}}
        </td>
        <td style="width:184.15pt; border-top:none; border-left:none; border-bottom:solid windowtext 1.0pt; border-right:solid windowtext 1.0pt" width="184">
          <p style="margin-bottom:.0001pt; padding:0cm 5.4pt 0cm 5.4pt"><span style="line-height:normal"><span style="font-family:Arial">{{param.title}}</span></span></p>
          <p style="margin-bottom:.0001pt; padding:0cm 5.4pt 0cm 5.4pt">&nbsp;</p>
        </td>
        <td style="width:77.95pt; border-top:none; border-left:none; border-bottom:solid windowtext 1.0pt; border-right:solid windowtext 1.0pt" width="78">
          <p align="center" style="margin-bottom:.0001pt; text-align:center; padding:0cm 5.4pt 0cm 5.4pt">{{param[currentYear - 4]}}</p>
        </td>
        <td style="width:77.95pt; border-top:none; border-left:none; border-bottom:solid windowtext 1.0pt; border-right:solid windowtext 1.0pt" width="78">
          <p align="center" style="margin-bottom:.0001pt; text-align:center; padding:0cm 5.4pt 0cm 5.4pt">{{param[currentYear - 3]}}</p>
        </td>
        <td style="width:77.95pt; border-top:none; border-left:none; border-bottom:solid windowtext 1.0pt; border-right:solid windowtext 1.0pt" width="78">
          <p align="center" style="margin-bottom:.0001pt; text-align:center; padding:0cm 5.4pt 0cm 5.4pt">{{param[currentYear - 2]}}</p>
        </td>
        <td style="width:77.95pt; border-top:none; border-left:none; border-bottom:solid windowtext 1.0pt; border-right:solid windowtext 1.0pt" width="78">
          <p align="center" style="margin-bottom:.0001pt; text-align:center; padding:0cm 5.4pt 0cm 5.4pt">{{param[currentYear - 1]}}</p>
        </td>
        <td style="width:77.95pt; border-top:none; border-left:none; border-bottom:solid windowtext 1.0pt; border-right:solid windowtext 1.0pt" width="78">
          <p align="center" style="margin-bottom:.0001pt; text-align:center; padding:0cm 5.4pt 0cm 5.4pt">{{param.points}}</p>
        </td>
      </tr>
      <tr style="height:24.75pt">
        <td colspan="6" style="width:453.35pt; border:solid windowtext 1.0pt; border-top:none" width="453">
          <p align="right" style="margin-bottom:.0001pt; text-align:right; padding:0cm 5.4pt 0cm 5.4pt"><span style="line-height:normal"><span style="height:24.75pt"><b><span style="font-family:Arial">Общее количество баллов</span></b></span></span></p>
        </td>
        <td style="width:56.55pt; border-top:none; border-left:none; border-bottom:solid windowtext 1.0pt; border-right:solid windowtext 1.0pt" width="57">
          <p align="center" style="margin-bottom:.0001pt; text-align:center; padding:0cm 5.4pt 0cm 5.4pt">{{report.report_data.total}}</p>
        </td>
      </tr>
      </tbody>
    </table>
    <br/>
    <p style="margin-bottom:.0001pt"><span style="line-height:normal"><b><span style="font-family:Arial">Статус:</span></b> {{this.report.status_crm}}</span></p>

    <p style="margin-bottom:.0001pt"><span style="line-height:normal"><b><span style="font-family:Arial">ФИО:</span></b> {{ user.fullname }}</span></p>

    <p style="margin-bottom:.0001pt"><span style="line-height:normal"><b><span style="font-family:Arial">Подпись:</span></b></span></p>

    <p style="margin-bottom:.0001pt">&nbsp;</p>
  </div>
</template>

<script>
    import {TokenService} from "@/services/token.service";
    export default {
        name: "FinanceResult",
        props: ['company', 'report', 'title'],
        data: () => {
            return {
              user: TokenService.getUser(),
              currentYear: new Date().getMonth()+1 >= 4 ? new Date().getFullYear() : new Date().getFullYear() - 1,
            }
        }
    }
</script>

<style scoped>
  #print-report {
    display: none;
  }
</style>
