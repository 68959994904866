// @ts-ignore
import axios from "axios"
import {useProgress} from '@marcoschulte/vue3-progress';
import qs from "qs";
import {TokenService} from "./token.service";

const ApiService = {
	_401interceptor: 0,

	init(baseURL) {
		axios.defaults.baseURL = baseURL;
	},

	setHeader() {
		if(TokenService.getToken()) {
			// @ts-ignore
			axios.defaults.headers.common["Authorization"] = `Bearer ${TokenService.getToken()}`
		}
	},

	removeHeader() {
		axios.defaults.headers.common["Authorization"] = null;
	},
  /* eslint-disable */
  get(resource, params = {}) {
		return axios.get(resource, {
		  params: params
    });
	},

	post(resource, data, withCredentials) {
		return axios.post(resource, qs.stringify(data), {
			headers: {
				"Content-Type": "application/x-www-form-urlencoded"
			},
			withCredentials: withCredentials
		});
	},

	put(resource, data) {
		return axios.put(resource, data);
	},

	delete(resource) {
		return axios.delete(resource);
	},

	customRequest(data) {
		return axios(data);
	},

	mountRequestInterceptor() {
    const progresses = [];

    axios.interceptors.request.use(config => {
      progresses.push(useProgress().start());
      return config;
    });

    axios.interceptors.response.use(resp => {
      progresses.pop()?.finish();
      return resp;
    }, (error) => {
      progresses.pop()?.finish();
      return Promise.reject(error);
    });
	}
}

export default ApiService;
