<template>
  <div v-if="report">
    <div v-if="print">
      <p><b><span style="font-size:11.0pt"><span style="font-family:Arial">{{index}}.	Оценка потенциала компании и команды для реализации экспортного проекта (в баллах):</span></span></b></p>
      <table class="MsoTableGrid" style="border-collapse:collapse; border:solid windowtext 1.0pt">
        <tbody>
        <tr style="height:22.85pt">
          <td style="width:36.95pt; border:solid windowtext 1.0pt" width="37">
            <p align="center" style="text-align:center; padding:0cm 5.4pt 0cm 5.4pt"><span style="height:22.85pt"><b><span style="font-size:11.0pt;font-family:Arial;">№№</span></b></span></p>
          </td>
          <td style="width:274.95pt; border:solid windowtext 1.0pt; border-left:none" width="275">
            <p align="center" style="text-align:center; padding:0cm 5.4pt 0cm 5.4pt"><span style="height:22.85pt"><b><span style="font-size:11.0pt;font-family:Arial;">Параметр</span></b></span></p>
          </td>
          <td style="width:155.3pt; border:solid windowtext 1.0pt; border-left:none" width="155">
            <p align="center" style="text-align:center; padding:0cm 5.4pt 0cm 5.4pt"><span style="height:22.85pt"><b><span style="font-size:11.0pt;font-family:Arial;">Показатель</span></b></span></p>
          </td>
        </tr>
        <tr>
          <td style="width:36.95pt; border:solid windowtext 1.0pt; border-top:none" width="37">
            <ol>
              <li style="padding:0cm 5.4pt 0cm 5.4pt">&nbsp;</li>
            </ol>
          </td>
          <td style="width:274.95pt; border-top:none; border-left:none; border-bottom:solid windowtext 1.0pt; border-right:solid windowtext 1.0pt" width="275">
            <p style="padding:0cm 5.4pt 0cm 5.4pt"><span style="font-size:11.0pt;font-family:Arial;">Структурированное интервью с представителями компании (итоговый балл)</span></p>

            <p style="padding:0cm 5.4pt 0cm 5.4pt">&nbsp;</p>
          </td>
          <td style="width:155.3pt; border-top:none; border-left:none; border-bottom:solid windowtext 1.0pt; border-right:solid windowtext 1.0pt" width="155">
            <p align="center" style="text-align:center; padding:0cm 5.4pt 0cm 5.4pt"><span style="font-size:11.0pt;font-family:Arial;"><span v-if="report && report.report_data">{{ report.report_data.points }}</span></span></p>
          </td>
        </tr>
        <tr style="height:24.35pt">
          <td colspan="3" style="width:467.2pt; border:solid windowtext 1.0pt; border-top:none" width="467">
            <p style="padding:0cm 5.4pt 0cm 5.4pt"><span style="height:24.35pt"><b><span style="font-size:11.0pt;font-family:Arial;">Оценка уровня потенциала реализации экспортного проекта</span></b></span></p>
          </td>
        </tr>
        <tr style="height:65.15pt">
          <td colspan="3" style="width:467.2pt; border:solid windowtext 1.0pt; border-top:none" width="467">
            <p align="center" style="text-align:center; padding:0cm 5.4pt 0cm 5.4pt"><span><span style="font-size:11.0pt;font-family:Arial;">{{report.status_crm}}</span></span></p>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <h5>{{index}}.	Оценка потенциала компании и команды для реализации экспортного проекта (в баллах):</h5>
      <table class="table-bordered table table-striped">
        <thead>
        <tr>
          <th>
            Параметр
          </th>
          <th>
            Показатель
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            Структурированное интервью с представителями компании (итоговый балл)
          </td>
          <td>
            <span v-if="report && report.report_data">{{ report.report_data.points }}</span>
          </td>
        </tr>
        <tr>
          <td>
            Оценка уровня потенциала реализации экспортного проекта
          </td>
          <td :class="'bg-' + report.status" class="font-weight-bold">
            <span>{{report.status_crm}}</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
    export default {
        name: "InterviewReport",
        props: ['company', 'print', 'index'],
        data() {
            return {
                report: null
            }
        },
        mounted() {
            this.report = this.company.enroll.reports.interview_report;
        }
    }
</script>

<style scoped>

</style>
