<template>
    <div v-if="company" class="animated fadeIn">
        <CRow>
            <CCol sm="12">
                <CCard>
                    <CCardHeader>
                        <router-link :to="'/company/' + id + '?tab=6'">←	 Назад к диагностике компании &laquo;<small>{{company.name}}</small>&raquo;</router-link>
                    </CCardHeader>
                  <CRow v-if="report.report_data">
                    <CCol>
                      <div class="float-end">
                        <CButtonGroup class="fixed shadow">
                          <CButton color="primary" class="text-white font-weight-bold">
                            Балл {{ report.report_data.points }}
                          </CButton>
                          <CButton color="warning" class="text-white font-weight-bold">
                            {{report.report_data.category}}
                          </CButton>
                        </CButtonGroup>
                      </div>
                    </CCol>
                  </CRow>
                    <CRow>
                        <CCol>
                            <CForm @submit.prevent="saveConfirm({rawData: report.raw_data, isSend: true})">
                              <div v-if="enrollment.form_result" class="form-result">
                                <div v-for="(form,key) in enrollment.form_result.form_category.forms" v-bind:key="key">
                                  <h5> {{ key + 1 }}&nbsp;.{{form.title}}</h5>
                                  <div v-for="(question,qKey) in form.form_questions" v-bind:key="qKey" class="form-group disabled" :aria-disabled="true">
                                    <label v-if="question.type === 'separator'" :for="qKey">{{ question.title }}</label>
                                    <label class="title" v-else :for="qKey"><strong>{{ question.indexKey }}. {{ question.title }}</strong></label>
                                    <CRow v-if="question.type !== 'separator'">
                                      <CCol md="6">
                                        <h6 class="text-primary"><i><b>Ответ компании:</b></i></h6>
                                        <template v-if="!question.answers.length && (question.type === 'typed' || question.type === 'email' || question.type === 'fio') && question.id !== anketaKey">
                                          <input class="form-control" :disabled="true" :required="question.is_required" type="text" :value="enrollment.form_result.answers[question.id]" />
                                        </template>
                                        <template v-if="question.type === 'typed_long'">
                                          <textarea v-model="enrollment.form_result.answers[question.id]" :disabled="true" :required="question.is_required"></textarea>
                                        </template>
                                        <template v-if="question.id === anketaKey">
                                          <CRow style="margin-left: 40px;" v-for="(answer, key) in enrollment.form_result.answers[anketaKey]" v-bind:key="key">
                                            <CCol size="md-6">
                                              <div class="form-group">
                                                <label>Страна</label>
                                                <input class="form-control" :disabled="true" type="text" :value="answer.country.originalObject.country_name_ru" />
                                              </div>
                                            </CCol>
                                            <CCol size="md-6">
                                              <div class="form-group">
                                                <label>Код ТН ВЭД</label>
                                                <input class="form-control" :disabled="true" type="text" :value="answer.code" />
                                              </div>
                                            </CCol>
                                          </CRow>
                                        </template>
                                        <template v-if="question.type !== 'separator' && question.answers.length && question.id !== anketaKey">
                                          <div class="block-form-control-wrapper">
                                            <div class="form-wrapper">
                                              <div v-if="question.answers.length" v-for="(answer, kAnswer) in question.answers" :key="kAnswer" class="radio">
                                                <label class="for-radio">
                                                  <input :disabled="true" type="radio" v-model="enrollment.form_result.answers[question.id]" :value="answer.title" :required="kAnswer == 0" :name="question.id" class="ch-settings">
                                                  &nbsp;
                                                  <span class="text" v-html="answer.title"></span>
                                                </label>
                                              </div>
                                            </div>
                                            <br/>
                                            <p class="text-blue"><i>{{question.description}}</i></p>
                                          </div>
                                        </template>
                                        <template v-if="question.type === 'separator'">
                                          <hr/>
                                        </template>
                                      </CCol>
                                      <CCol md="6">
                                        <h6 class="text-success"><i><b>Оценка специалиста:</b></i></h6>
                                        <div :disabled="report.is_send">
                                          <template v-if="[7,8,12,13,15,1,2,3,4,5].indexOf(question.indexKey) === -1">
                                            <template v-if="!question.answers.length && (question.type === 'typed' || question.type === 'email' || question.type === 'fio')">
                                              <input class="form-control" required type="text" v-model="report.raw_data[question.id]" />
                                            </template>
                                            <template v-if="question.type === 'typed_long'">
                                              <textarea v-model="report.raw_data[question.id]" required></textarea>
                                            </template>
                                            <template v-if="question.type !== 'separator' && question.answers.length">
                                              <div class="block-form-control-wrapper">
                                                <div class="form-wrapper">
                                                  <div v-if="question.answers.length" v-for="(answer, kAnswer) in question.answers" :key="kAnswer" class="radio">
                                                    <label :for="answer.id" class="for-radio">
                                                      <input type="radio" :id="answer.id" v-model="report.raw_data[question.id]" :value="answer.title" required :name="'crm_' + question.id" class="ch-settings">
                                                      &nbsp;
                                                      <span class="text" v-html="answer.title"></span>
                                                    </label>
                                                  </div>
                                                </div>
                                                <br/>
                                                <p class="text-blue"><i>{{question.description}}</i></p>
                                              </div>
                                            </template>
                                            <template v-if="question.type === 'separator'">
                                              <hr/>
                                            </template>
                                          </template>
                                          <template v-else>
                                            <h6 class="text-dark font-weight-bold text-muted">Не верифицируется</h6>
                                          </template>
                                        </div>
                                      </CCol>
                                    </CRow>
                                    <hr/>
                                  </div>
                                </div>
                              </div>
                              <div class="mb-4">
                                <router-link :to="'/company/' + id + '?tab=6'">
                                  <button class="btn btn-sm btn-outline">
                                    <i class="fa fa-arrow-circle-left"></i>
                                    Назад
                                  </button>
                                </router-link>
                                <CButtonGroup class="float-end">
                                  <button v-if="!report.is_send" @click="updateReport({rawData: report.raw_data})" type="button" class="btn btn-secondary btn-sm">
                                    <i class="fa fa-save"></i>
                                    В черновик
                                  </button>
                                  <CButton v-if="!report.is_send" type="submit" class="btn btn-sm" color="success">
                                    <i class="fa fa-dot-circle-o"></i>&nbsp;Сохранить
                                  </CButton>
                                  </CButtonGroup>
                              </div>
                            </CForm>
                        </CCol>
                    </CRow>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import {TokenService} from "@/services/token.service";
    import ApiService from "@/services/api.service";
    import FileUpload from 'vue-upload-component'
    export default {
        name: "DiagnostFormVerify",
        components: {
            FileUpload
        },
        data: () => {
            return {
                company: null,
                report: null,
                enrollment: {},
                kKey: 0,
                user: TokenService.getUser(),
                postAction: process.env.VUE_APP_ROOT_API + 'api/attaches',
                labelTxt: {
                    dataOn: 'да',
                    dataOff: 'нет'
                },
                headers: TokenService.getHeaders(),
                anketaKey: 128
            }
        },
        mounted() {
            this.id = this.$route.params.id;
            ApiService
                .get('api/mentoring/enrolls/' + this.id + '/company')
                .then((res) => {
                    this.company = res.data;
                    this.report = this.company.enroll.reports.verify_form_report;
                    if(!this.report.raw_data) {
                        this.report.raw_data = {};
                    }
                    this.enrollment = this.company.enroll;
                    if(this.enrollment.program && this.enrollment.program.id === 2) {
                      this.anketaKey = 3866
                    }
                    let indexKey = 0;
                    this.enrollment.form_result.form_category.forms.forEach((form, formKey) => {
                        form.form_questions.forEach((question, questionKey) => {
                            if(question.type !== 'separator') {
                                indexKey++;
                                this.enrollment.form_result.form_category.forms[formKey].form_questions[questionKey].indexKey = indexKey;
                            }
                        })
                    });
                })
            ;
        },
        methods: {
            saveConfirm(params) {
              this.updateReport(params);
                /*this.$bvModal.msgBoxConfirm('Пожалуйста, убедитесь, что вы верно заполнили все ответы анкеты. При нажатии Ок произойдет завершение работ с анкетой и окончательное сохранение этапа диагностики.', {
                    title: 'Подтвердите действие:',
                    okVariant: 'danger',
                    okTitle: 'Да',
                    cancelTitle: 'Нет',
                    hideHeaderClose: false,
                    centered: true
                }).then(res => {
                    if(res) {

                    }
                })*/
            },
            updateReport(params) {
                return ApiService
                    .put('api/mentoring/enrollreports/' + this.report.id, params)
                    .then(res => {
                        this.report = res.data;
                        this.$toast.show('Изменения успешно сохранены', {type: 'success'});
                    })
            }
        }
    }
</script>

<style scoped>
    .result {
        border-radius: 3px;
        padding: 3px 10px;
    }
  .fixed {
    position: fixed;
    right: 50px;
    top: 130px;
    z-index: 1020;
    border: 1px solid #c8ced3;
  }
  .fixed > .btn {
    border-radius: 0;
   }
    input:checked + .text {
      text-decoration: underline;
      font-weight: bold;
      color: #323232;
    }
</style>
