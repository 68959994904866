<template>
    <div v-if="company" class="animated fadeIn">
        <CRow>
            <CCol sm="12">
                <CCard>
                  <CCardHeader>
                      <router-link :to="'/company/' + id + '?tab=6'">←	 Назад к диагностике компании &laquo;<small>{{company.name}}</small>&raquo;</router-link>
                  </CCardHeader>
                  <CRow>
                    <CCol>
                      <div class="float-end">
                        <CButtonGroup class="fixed shadow">
                          <CButton :color="report.status" class="font-weight-bold">
                            <i class="fa fa-exclamation"></i>&nbsp;
                            {{report.status_crm}}
                          </CButton>
                          <CButton color="primary" class="text-white font-weight-bold">
                            Балл {{ company.enroll.form_result.result.points }}
                          </CButton>
                          <CButton color="warning" class="text-white font-weight-bold">
                            {{company.enroll.form_result.result.category}}
                          </CButton>
                        </CButtonGroup>
                      </div>
                      <FormResult :comment="report.comment" :enroll="company.enroll"></FormResult>
                    </CCol>
                  </CRow>
                  <CRow>
                      <CCol>
                          <CForm @submit.prevent="submit" style="padding: 0 20px 20px;">
                             <div class="mt-4">
                               <CFormTextarea :required="report.status === 'danger'" :disabled="report.is_send" placeholder="Комментарий специалиста по итогам этапа диагностики" v-model="report.comment" />
                             </div>
                            <div class="mt-4">
                                <router-link :to="'/company/' + id + '?tab=6'" class="btn btn-sm btn-outline">
                                  <i class="fa fa-arrow-circle-left"></i>
                                  Назад
                                </router-link>
                                <CButtonGroup class="float-end">
                                  <button @click="print" type="button" class="btn btn-primary btn-sm text-white">
                                    <i class="fa fa-print"></i>
                                    Распечатать
                                  </button>
                                  <CButton v-if="!report.is_send" type="submit" class="btn-sm" color="success">
                                    <i class="fa fa-question-circle"></i>&nbsp;Одобрить
                                  </CButton>
                                </CButtonGroup>
                              </div>
                          </CForm>
                      </CCol>
                    </CRow>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import ApiService from "@/services/api.service";
    import {TokenService} from "@/services/token.service";
    import FormResult from "./FormResult";
    import FileUpload from 'vue-upload-component'
    export default {
        name: "DiagnostForm",
        components: {
            FormResult,
            FileUpload},
        data: () => {
            return {
                company: null,
                report: null,
                user: TokenService.getUser(),
                postAction: process.env.VUE_APP_ROOT_API + 'api/attaches',
                labelTxt: {
                    dataOn: 'да',
                    dataOff: 'нет'
                },
                headers: TokenService.getHeaders(),
            }
        },
        mounted() {
            this.id = this.$route.params.id;
            ApiService
                .get('api/mentoring/enrolls/' + this.id + '/company')
                .then((res) => {
                    this.company = res.data;
                    this.report = this.company.enroll.reports.form_report;
                })
            ;
        },
        methods: {
            updateReport(params, message = true) {
                return ApiService
                    .put('api/mentoring/enrollreports/' + this.report.id, params)
                    .then(res => {
                        this.report = res.data;
                        if(message) {
                            this.$toast.show('Изменения сохранены', {type: 'success'});
                        }
                    })
            },
            submit() {
              this.updateReport({isSend: true, comment: this.report.comment})
            },
            print() {
                let block = window.open('', 'PRINT');
                block.document.write('<html><head>');
                block.document.write('<style>' +
                    'h1 {\n' +
                    '    font-size: 12pt;\n' +
                    '  }' +
                    'body {\n' +
                    '  margin: 20px 10px 20px 20px; font-family: \'Helvetica\', Arial;\n' +
                    '    font-size: 11pt;\n' +
                    '  }' +
                    'table {\n' +
                    '  border-collapse: collapse; width: 50%;\n' +
                    '}\n' +
                    '\n' +
                    'table, th, td {\n' +
                    '  border: 1px solid black;\n' +
                    '}' +
                    '</style>');
                block.document.write('<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">');
                block.document.write('</head><body class="receipt">');
                block.document.write(document.getElementById('print').innerHTML);
                block.document.write('</body></html>');
                block.document.close();
                block.focus();
                block.print();
                block.close();
                return true;
            }
        },
        computed: {
            isNoArchive() {
                return this.report ? this.report.is_no_archive : null
            }
        },
        watch: {
            isNoArchive: {
                handler: function(newVal, oldVal) {
                    console.log(newVal, oldVal);
                    if(oldVal !== null && newVal !== oldVal) {
                        this.updateReport({isNoArchive: newVal})
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .result {
        border-radius: 3px;
        padding: 3px 10px;
    }
  .fixed {
    position: fixed;
    right: 50px;
    top: 130px;
    z-index: 1020;
    border: 1px solid #c8ced3;
  }
  .fixed > .btn {
    border-radius: 0;
   }
</style>
