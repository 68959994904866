<template>
  <div v-if="company" class="animated fadeIn">
    <CRow>
      <FinanceResult :company="company" :report="report" :title="'Оценка финансового состояния компании'"/>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <ul class="list-group float-end">
              <li class="list-group-item py-2" :class="'list-group-item-' + report.status">
                {{ report.status_crm }}
              </li>
            </ul>
            <strong>Диагностика компании </strong>&laquo;<small>{{company.name}}</small>&raquo;
          </CCardHeader>
          <form @submit.prevent="saveConfirm()">
            <div :disabled="report.status === 'secondary' || report.is_send">
              <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>Показатель</th>
                    <th>{{ year3 }}</th>
                    <th>{{ year2 }}</th>
                    <th>{{ year1 }}</th>
                    <th v-if="report.is_send">Баллы</th>
                  </tr>
                </thead>
                <tbody>
                <tr>
                  <td>Динамика выручки</td>
                  <td><b-input required type="number" step="0.001" v-model="details[year3].revenue_dynamics"/></td>
                  <td><b-input required type="number" step="0.001" v-model="details[year2].revenue_dynamics"/></td>
                  <td><b-input required type="number" step="0.001" v-model="details[year1].revenue_dynamics"/></td>
                  <td v-if="report.is_send && report.report_data">{{ report.report_data.params[0].points }}</td>
                </tr>
                <tr>
                  <td>Динамика прибыли от продаж</td>
                  <td><b-input required type="number" step="0.001" v-model="details[year3].profit_loss_dynamics"/></td>
                  <td><b-input required type="number" step="0.001" v-model="details[year2].profit_loss_dynamics"/></td>
                  <td><b-input required type="number" step="0.001" v-model="details[year1].profit_loss_dynamics"/></td>
                  <td v-if="report.is_send && report.report_data">{{ report.report_data.params[1].points }}</td>
                </tr>
                <tr>
                  <td>Чистые активы</td>
                  <td><b-input required type="number" step="0.001" v-model="details[year3].clear_active"/></td>
                  <td><b-input required type="number" step="0.001" v-model="details[year2].clear_active"/></td>
                  <td><b-input required type="number" step="0.001" v-model="details[year1].clear_active"/></td>
                  <td v-if="report.is_send && report.report_data">{{ report.report_data.params[2].points }}</td>
                </tr>
                </tbody>
              </table>
              <div v-if="report.report_data" class="float-end">
                <h5 v-if="report.report_data.total !== false">Общий балл: {{report.report_data.total}}</h5>
                <h5 v-else>Общий балл: <span class="text-danger">ошибка расчета</span></h5>
              </div>
            </div>
            </div>
            <div>
              <CButtonGroup class="pull-left">
                <router-link :to="'/company/' + id + '?tab=6'" class="btn btn-sm btn-outline">
                  <i class="fa fa-arrow-circle-left"></i>
                  Назад
                </router-link>
              </CButtonGroup>
              <CButtonGroup class="float-end">
                <button v-if="!report.is_send && report.status !== 'secondary'" @click="updateReport({status: 'secondary'})" type="button" class="btn btn-info btn-sm text-white">
                  <i class="fa fa-upload"></i>
                  Запросить данные у компании
                </button>
                <button v-if="!report.is_send" @click="updateDetails()" type="button" class="btn btn-secondary btn-sm">
                  <i class="fa fa-save"></i>
                  В черновик
                </button>
                <button v-if="!report.is_send" type="submit" class="btn btn-success btn-sm">
                  <i class="fa fa-dot-circle-o"></i>
                  Сохранить
                </button>
                <button @click="print" v-if="report.is_send && report.report_data" type="button" class="btn btn-primary btn-sm text-white">
                  <i class="fa fa-print"></i>
                  Распечатать
                </button>
              </CButtonGroup>
            </div>
          </form>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
    import ApiService from "@/services/api.service";
    import FinanceResult from "./FinanceResult";
    export default {
        name: "DiagnostFinanceDetail",
        components: {
            FinanceResult
        },
        data() {
            return {
                company: null,
                report: {},
                id: null,
                details: {},
                year3: null,
                year2: null,
                year1: null
            }
        },
        mounted() {
            this.id = this.$route.params.id;
            ApiService
                .get('api/mentoring/enrolls/' + this.id + '/company')
                .then((res) => {
                    this.company = res.data;
                    this.report = this.company.enroll.reports.finance_report;
                    this.details = this.company.enroll.reports.finance_report.details;
                    let years = Object.keys(this.details);
                    this.year3 = years[0];
                    this.year2 = years[1];
                    this.year1 = years[2];
                })
            ;
        },
        methods: {
            saveConfirm() {
              this.updateDetails(true);
                /*this.$bvModal.msgBoxConfirm('Пожалуйста, убедитесь, что вы верно заполнили все данные! При нажатии Ок работа с формой завершится, произойдет подсчет данных и окончательное завершение этапа диагностики.', {
                    title: 'Подтвердите действие:',
                    okVariant: 'danger',
                    okTitle: 'Да',
                    cancelTitle: 'Нет',
                    hideHeaderClose: false,
                    centered: true
                }).then(res => {
                    if(res) {

                    }
                })*/
            },
            updateDetails(send = false) {
                let eachKey = 0;
                [this.year3, this.year2, this.year1].forEach((year) => {
                    let detail = this.details[year];
                    eachKey++;
                    ApiService.put('/api/mentoring/accountingdetails/' + detail.id, {
                      clearActive: detail.clear_active,
                      profitLossDynamics: detail.profit_loss_dynamics,
                      revenueDynamics: detail.revenue_dynamics
                    }).then(res => {
                        this.details[year] = res.data;
                        if(eachKey === 3 && send) {
                            this.updateReport({isSend: true});
                        }
                    })
                })
            },
            print() {
                let block = window.open('', 'PRINT');
                block.document.write('<html><head>');
                block.document.write('<style>@page { size: portrait;}</style>');
                block.document.write('</head><body>');
                block.document.write(document.getElementById('print-report').innerHTML);
                block.document.write('</body></html>');
                block.document.close();
                block.focus();
                block.print();
                block.close();
                return true;
            },
            updateReport(params, message = true) {
                return ApiService
                    .put('api/mentoring/enrollreports/' + this.report.id, params)
                    .then(res => {
                        this.report = res.data;
                        if(message) {
                            this.$toast.show('Изменения сохранены', {type: 'success'});
                        }
                    })
            },
        }
    }
</script>

<style scoped type="scss">
</style>
