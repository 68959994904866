<template>
    <div v-if="company" class="animated fadeIn">
        <FactorsResult :company="company" :factors="factors" :colors="colors" :report="report" :comment="report.comment" ></FactorsResult>
        <CRow>
            <CCol sm="12">
                <CCard class="no-border">
                    <CForm @submit.prevent="updateReport({reportData: factors, status: hasSuccess ? 'success' : (hasWarning ? 'warning' : 'danger'), isSend: true, comment: report.comment})">
                      <CCard :header-bg-color="colors[key].status" v-for="(factor, key) in factors" :key="key" class="no-border">
                        <CCardBody>
                          <CRow>
                            <CCol>
                              <div class="mt-4">
                                <h4>{{factor.country.originalObject.country_name_ru}}</h4>
                                <div class="d-inline"><strong>Код ТН ВЭД:</strong>&nbsp;{{factor.code}}</div>&nbsp;
                                <div :class="'label bg-' + colors[key].status" class="d-inline">
                                  <i class="fa fa-exclamation"></i>&nbsp;
                                  {{ colors[key].statusText }}
                                </div>
                              </div>
                              <div class="mt-4" :disabled="factor.isBlock || report.is_send">
                                <CRow>
                                  <CCol md="6">
                                    <label>Наименование товара:</label>
                                    <CFormInput :class="v.factors.$each.$response.$data[key].product.$invalid ? 'is-invalid' : 'is-valid'" :required="!factor.isBlock" v-model="factor.product" type="text" placeholder="Введите товар" />
                                  </CCol>
                                  <CCol md="6"></CCol>
                                </CRow>
                              </div>
                              <div class="mt-4" :disabled="factor.isBlock || report.is_send">
                                <CRow>
                                  <CCol md="6"><h5>Барьер</h5></CCol>
                                  <CCol md="6"><h5>Оценка</h5></CCol>
                                </CRow>
                                <CRow v-for="(bar,i) in factor.bars" :key="i">
                                  <CCol md="6">
                                    <div class="mt-4">
                                      <CFormTextarea v-model="bar.name" type="text" :class="v.factors.$each.$response.$data[key].bars.$each.$data[i].name.$invalid ? 'is-invalid' : 'is-valid'" :required="bar.status !== ''" placeholder="Введите барьер" />
                                    </div>
                                  </CCol>
                                  <CCol md="6">
                                    <CRow class="mt-4">
                                      <CCol md="6">
                                        <div class="circle-block">
                                          <input class="circle-input" :required="bar.name !== ''" v-model="bar.status" :id="'red-'+key+'-'+i" value="red" type="radio"/>
                                          <label :for="'red-'+key+'-'+i" class="bg-danger circle" >
                                            <i class="icon-circle"></i>
                                          </label>
                                          <input class="circle-input" v-model="bar.status" :id="'yellow-'+key+'-'+i" value="yellow" type="radio"/>
                                          <label :for="'yellow-'+key+'-'+i" class="bg-warning circle">
                                            <i class="icon-circle"></i>
                                          </label>
                                          <input class="circle-input" v-model="bar.status" :id="'green-'+key+'-'+i" value="green" type="radio"/>
                                          <label :for="'green-'+key+'-'+i" class="bg-success circle">
                                            <i class="icon-circle"></i>
                                          </label>
                                          <label>
                                            <CButton style="margin: -40px 0 0 20px;" v-if="i>2" color="danger" size="sm" @click="factor.bars.splice(i,1)"><i class="fa fa-trash"></i></CButton>
                                          </label>
                                        </div>
                                      </CCol>
                                    </CRow>
                                  </CCol>
                                </CRow>
                                <CRow>
                                  <CCol md="6"></CCol>
                                  <CCol md="6">
                                    <CButton :disabled="factor.isBlock || report.is_send" style="margin-left: 180px;" v-if="factor.bars.length <=10" class="float-start" color="success" size="sm" @click="factor.bars.push({name:'',status:''})"><i class="fa fa-plus"></i> Добавить</CButton>
                                  </CCol>
                                </CRow>
                              </div>
                            </CCol>
                          </CRow>
                        </CCardBody>
                      </CCard>
                      <CRow>
                        <CCol>
                          <CFormTextarea v-model="report.comment" required :disabled="report.is_send" placeholder="Комментарий специалиста по итогам этапа диагностики"></CFormTextarea>
                        </CCol>
                      </CRow>
                      <CRow class="mt-4">
                        <CCol>
                          <router-link :to="'/company/' + id + '?tab=6'" class="btn btn-sm btn-outline">
                            <i class="fa fa-arrow-circle-left"></i>
                            Назад
                          </router-link>
                          <CButtonGroup class="mb-2 float-end">
                            <button :disabled="v.$invalid" v-if="!report.is_send && hasFailed && (report.raw_data ? report.raw_data.length : 0) < 3 && this.factors.length < 6" @click="updateReport({status: 'secondary', reportData: factors})" type="button" class="btn btn-info btn-sm text-white">
                              <i class="fa fa-upload"></i>
                              Запросить иные страны и коды ТН ВЭД
                            </button>
                            <button v-if="report.is_send" @click="print" type="button" class="btn btn-primary btn-sm text-white">
                              <i class="fa fa-print"></i>
                              Распечатать
                            </button>
                            <button v-if="!report.is_send" @click="updateReport({reportData: factors})" type="button" class="btn btn-secondary btn-sm">
                              <i class="fa fa-save"></i>
                              В черновик
                            </button>
                            <button :disabled="v.$invalid" v-if="!report.is_send" type="submit" class="btn btn-success btn-sm">
                              <i class="fa fa-dot-circle-o"></i>
                              Сохранить
                            </button>
                          </CButtonGroup>
                        </CCol>
                      </CRow>
                    </CForm>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import FactorsResult from "@/components/diagnost/FactorsResult";
    import { helpers, required, minLength } from '@vuelidate/validators'
    import useVuelidate from '@vuelidate/core'
    import { reactive } from 'vue'
    import ApiService from "@/services/api.service";
    const barValidate = (value, bar) => {
        return bar.status !== '' ? bar.name !== '' : true;
    };
    export default {
      name: "DiagnostFactors",
      components: {
          FactorsResult
      },
      props: ['data', 'tnvcode'],
      data: () => {
        return {
          v: null,
          visibleLiveDemo: true,
            company: null,
            report: null,
          showModal: false,
            labelTxt: {
                dataOn: 'да',
                dataOff: 'нет'
            },
            statusText: {
                'success': 'Выявлены некритичные барьеры / не выявлены барьеры',
                'warning': 'Выявлены барьеры, которые могут вызвать сложности при экспорте',
                'danger': 'Выявлены критичные заградительные барьеры или более 5, которые могут вызвать сложности при экспорте',
            },
            factors: []
        }
      },
      mounted() {
        this.init()
      },
      computed: {
          colors: function() {
              let colorsArray = [];
              this.factors.forEach((factor) => {
                  let colors = {red: 0, yellow: 0, green: 0, isFailed: false, status: 'success', statusText: this.statusText['success'], isBlock: false}
                  factor.bars.forEach((bar) => {
                      if (bar.status === 'red') {
                         colors.red++;
                      }
                      if (bar.status === 'yellow') {
                          colors.yellow++;
                      }
                      if (bar.green === 'green') {
                          colors.green++;
                      }
                  });
                  colors.isBlock = factor.isBlock;
                  colors.isFailed = colors.yellow >= 5 || colors.red > 0;

                  colors.status = colors.yellow >= 1 && colors.yellow < 5 && !colors.red ? 'warning' : 'success';
                  colors.status = colors.isFailed ? 'danger' : colors.status;

                  colors.statusText = this.statusText[colors.status];

                  colorsArray.push(colors);
              });
              return colorsArray;
          },
          hasFailed: function() {
              return this.colors.filter(color => color.isFailed && !color.isBlock).length > 0;
          },
          hasSuccess: function() {
              return this.colors.filter(color => color.status === 'success').length > 0;
          },
          hasWarning: function() {
              return this.colors.filter(color => color.status === 'warning').length > 0;
          }
      },
      methods: {
        init() {
          if(this.data) {
            this.company = this.data
            this.report = this.company.enroll.reports.stop_factors_report;
            this.factors = this.company.enroll.reports.stop_factors_report.report_data;
            let forms = [];
            this.factors.forEach((factor) => {
              factor.code = this.tnvcode
              if(!factor.bars) {
                factor.bars = [];
                let i = 0;
                while (i < 3) {
                  factor.bars.push({name: '', status: ''});
                  i++;
                }
              }

              if(!factor.product) {
                factor.product = ''
              }

              forms.push(factor);
            })
            this.factors = forms;
            this.factors = this.cloneObj(this.factors)
            console.log(this.factors)
            const rules = {
              factors: {
                $each: helpers.forEach({
                  product: {
                    required,
                    minLength: minLength(3),
                  },
                  bars: {
                    $each: helpers.forEach({
                      name: {
                        barValidate
                      }
                    })
                  }
                })
              }
            }

            const state = reactive({factors: this.factors})

            this.v = useVuelidate(rules, state)

          }
        },
          print() {
              let block = window.open('', 'PRINT');
              block.document.write('<html><head>');
              block.document.write('<style>@page { size: portrait;}</style>');
              block.document.write('</head><body>');
              block.document.write(document.getElementById('print').innerHTML);
              block.document.write('</body></html>');
              block.document.close();
              block.focus();
              block.print();
              block.close();
              return true;
          },
          cloneObj(src) {
            return JSON.parse(JSON.stringify(src));
          },
          updateReport(params, message = true, validate = true) {
              if (validate) {
                  let isError = false;
                  this.factors.forEach((factor, factorKey) => {
                      factor.bars.forEach((bar, barKey) => {
                          if(bar.status !== '' && bar.name === '') {
                              this.factors[factorKey].bars[barKey].isError = true;
                              isError = true;
                          }
                      });
                  });
                  if(isError) {
                      return false;
                  }
              }

              let rawCount = this.report.raw_data ? this.report.raw_data.length : 0;
              if(params.reportData) {
                  let factors = [];
                  let newCount = 0;
                  params.reportData.forEach((factor,key) => {
                      factor.isFailed = this.colors[key].isFailed;
                      if(factor.isFailed && !factor.isBlock && params.status === 'secondary') {
                          newCount++;
                          if((rawCount + newCount) <= 3) {
                              factor.isNew = true;
                          }
                          factor.isBlock = true;
                      }

                      factors.push(factor);
                  });
                  params.reportData = factors;
              }


              if(params.isSend) {
                this.visibleLiveDemo = true
                ApiService
                  .put('api/mentoring/enrollreports/' + this.report.id, params)
                  .then(res => {
                    this.report = res.data;
                    if(message) {
                      this.$toast.show('Изменения сохранены', {type: 'success'});
                    }
                  })
              } else {
                  return ApiService
                      .put('api/mentoring/enrollreports/' + this.report.id, params)
                      .then(res => {
                          this.report = res.data;
                          if(message) {
                              this.$toast.show('Изменения сохранены', {type: 'success'});
                          }
                      })
              }
          }
      }
    }
</script>

<style scoped>
    .circle {
      display: inline-block;
      line-height: 32px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      text-align: center;
      margin-right: 10px;
      cursor: pointer;
      position: relative;
    }
    .circle-input {
      visibility:hidden;
    }
    .icon-circle {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -8px -8px;
      height: 16px;
      width: 16px;
      line-height: 1;
      background: #fff;
      border-radius: 50%;
    }
    input:checked + label > .icon-circle {
      background: #6c757d;
    }
    .fixed {
      position: fixed;
      right: 50px;
      top: 130px;
      z-index: 1020;
      border: 1px solid #c8ced3;
    }
    .fixed > .btn {
      border-radius: 0;
    }
    .circle-block {
      margin-top: 10px;
    }
  .label {
    padding: 2px 3px;
    border-radius: 5px;
    font-weight: bold;
  }
  .no-border {
    border: none;
  }
  div[disabled="true"] input, div[disabled="true"] textarea {
    pointer-events: none;
    cursor: not-allowed;
    --cui-bg-opacity: 1;
    background-color: rgba(var(--cui-light-rgb), var(--cui-bg-opacity)) !important;
  }
</style>
