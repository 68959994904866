<template>
  <div v-if="comment">
    <div v-if="print">
      <p><b><span style="font-size:11.0pt"><span style="font-family:Arial">1. Телефонный опрос компании, верификация сведений о компании</span></span></b></p>
      <table class="MsoTableGrid" style="border-collapse:collapse; border:solid windowtext 1.0pt">
        <tbody>
        <tr style="height:24.85pt">
          <td style="width:467.2pt; border:solid windowtext 1.0pt" width="467">
            <p style="padding:0cm 5.4pt 0cm 5.4pt"><span style="height:24.85pt"><b><span style="font-size:11.0pt"><span style="font-family:Arial">Заключение специалиста, проводившего телефонный опрос</span></span></b></span></p>
          </td>
        </tr>
        <tr>
          <td style="width:467.2pt; border:solid windowtext 1.0pt; border-top:none" width="467">
            <p style="padding:0cm 5.4pt 0cm 5.4pt"><span style="font-family:Arial; font-size:11.0pt;">{{ comment }}</span></p>
          </td>
        </tr>
        <tr>
          <td style="width:467.2pt; border:solid windowtext 1.0pt; border-top:none" width="467">
            <p style="padding:0cm 5.4pt 0cm 5.4pt"><b><span style="font-size:11.0pt"><span style="font-family:Arial">Дата проведения информационного мероприятия: </span></span></b><span style="font-family:Arial; font-size:11.0pt;">{{eventDate}}</span></p>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <h5>1.	Телефонный опрос компании, верификация сведений о компании</h5>
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th>Параметр</th>
          <th>Показатель</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            Заключение специалиста, проводившего телефонный опрос:
          </td>
          <td>
            {{ comment }}
          </td>
        </tr>
        <tr>
          <td>
            Дата проведения информационного мероприятия:
          </td>
          <td>
            {{eventDate}}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
    export default {
        name: "QuestReport",
        props: ['comment', 'eventDate', 'print']
    }
</script>

<style scoped>

</style>
