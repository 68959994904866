import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//import CoreuiVue from '@coreui/vue'
import moment from 'moment'
import CoreuiVue from '@coreui/vue-pro'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsCallout from '@/components/DocsCallout'
import DocsExample from '@/components/DocsExample'
import ApiService from "@/services/api.service";
import {TokenService} from "@/services/token.service";
import mitt from 'mitt';
const emitter = mitt();
import { createToaster } from "@meforma/vue-toaster";
/*import BootstrapVue3 from 'bootstrap-vue-3'*/
import { vfmPlugin } from 'vue-final-modal'
import SimpleTypeahead from 'vue3-simple-typeahead';
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css'; //Optional default CSS
import {Vue3ProgressPlugin} from '@marcoschulte/vue3-progress';
import VueSocketIO from 'vue-3-socket.io'

ApiService.init(process.env.VUE_APP_ROOT_API);
ApiService.setHeader()
ApiService.mountRequestInterceptor()
ApiService.get('api/user').then(res => {
  TokenService.clearUser()
  TokenService.setUser(res.data)
}).catch(() => {
  TokenService.signOut()
})

const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(new VueSocketIO({
  debug: true,
  connection: process.env.VUE_APP_R_URL
}))
app.use(Vue3ProgressPlugin)
app.use(vfmPlugin)
app.use(SimpleTypeahead)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsCallout', DocsCallout)
app.component('DocsExample', DocsExample)
app.config.globalProperties.emitter = emitter;
app.config.globalProperties.truncate = function(text, length = 30, clamp = '...') {
  return text.length > length ? text.slice(0, length) + clamp : text;
}
app.config.globalProperties.getAvatar = function (user) {
  if(user.avatar && user.avatar['100x']) {
    return 'https:' + user.avatar['100x']
  }
  return 'https://exportedu.ru/images/pics/user-pic.jpg'
}
app.config.globalProperties.$toast = createToaster({position: 'top'});
app.config.globalProperties.formatDate = function(value, format) {
  format = format || 'DD.MM.YYYY в HH:mm'
  if (value) {
    return moment(String(value)).format(format)
  }
}

app.mount('#app')
