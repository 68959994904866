<template>
    <div v-if="company" class="animated fadeIn">
      <FinanceResult :company="company" :report="report"/>
      <div v-if="!report.report_data">
        <CRow>
          <CCol sm="6" class="mx-auto">
          <CCard>
            <CCardHeader>
              <strong>Диагностика компании </strong>&laquo;<small>{{company.name}}&raquo;(ОГРН {{company.ogrn}})</small>
            </CCardHeader>
            <CForm @submit.prevent="saveConfirm({isSend: true})">
             <div class="mt-4 mb-4 text-center">
               <CButton type="submit" color="success">
                 <i class="fa fa-dot-circle-o"></i>
                 Провести проверку
               </CButton>
             </div>
            </CForm>
          </CCard>
          </CCol>
        </CRow>
      </div>
      <div v-else>
        <CRow>
          <CCol>
            <CCard>
              <CCardHeader>
                <ul class="list-group float-end">
                  <li class="list-group-item py-2" :class="'list-group-item-' + report.status">
                    {{ report.status_crm }}
                  </li>
                </ul>
                <strong>Диагностика компании </strong>&laquo;<small>{{company.name}}&raquo;(ОГРН {{company.ogrn}})</small>
              </CCardHeader>
              <CBTable :fields="tableFields" :items="report.report_data.params" />
              <div class="float-end m-lg-auto">
                <h5 v-if="report.report_data.total !== false">Общий балл: {{report.report_data.total}}</h5>
                <h5 v-else>Общий балл: <span class="text-danger">ошибка расчета</span></h5>
              </div>
              <CCardFooter>
                <button @click="print" v-if="report.status !== 'secondary'" type="button" class="btn btn-primary btn-sm text-white float-end">
                  <i class="fa fa-print"></i>
                  Распечатать
                </button>
              </CCardFooter>
            </CCard>
          </CCol>
        </CRow>
      </div>
    </div>
</template>

<script>
    import ApiService from "@/services/api.service";
    import FinanceResult from "./FinanceResult";
    import CBTable from "@/components/CBTable";
    export default {
        name: "DiagnostFinance2",
        props: ['companyId'],
        components: {
          CBTable,
            FinanceResult
        },
        data: () => {
            return {
                company: null,
                accounting: [],
                report: null,
                rawData: {},
                currentYear: new Date().getMonth()+1 >= 4 ? new Date().getFullYear() : new Date().getFullYear() - 1,
                tableFields: [],
            }
        },
        mounted() {
          this.emitter.on('report:reload', () => {
            this.load()
          })
          this.load()
        },
        methods: {
            load() {
              if(this.company) {
                this.id = this.companyId;
              } else {
                this.id = this.$route.params.id;
              }

              this.tableFields.push({
                key: 'title',
                label: 'Название параметра'
              });
              for (let i = 4; i >= 1; i--) {
                this.tableFields.push({
                  key: (this.currentYear - i).toString()
                });
              }
              this.tableFields.push({
                key: 'points',
                label: 'Баллы'
              });
              ApiService
                .get('api/mentoring/enrolls/' + this.id + '/company')
                .then((res) => {
                  this.company = res.data;
                  this.report = this.company.enroll.reports.finance_express_report;
                })
              ;
            },
            saveConfirm(params) {
              this.updateReport(params);
            },
            updateReport(params) {
                return ApiService
                    .put('api/mentoring/enrollreports/' + this.report.id, params)
                    .then(res => {
                        this.report = res.data;
                        this.emitter.emit('finance_report', ['success', 'warning', 'secondary'].indexOf(this.report.status) > -1);
                        /*if(this.report.status === 'danger') {
                          this.$bvModal.show('archive');
                        }*/
                    })
            },
            print() {
                let block = window.open('', 'PRINT');
                block.document.write('<html><head>');
                block.document.write('<style>@page { size: portrait;}</style>');
                block.document.write('</head><body>');
                block.document.write(document.getElementById('print-report').innerHTML);
                block.document.write('</body></html>');
                block.document.close();
                block.focus();
                block.print();
                block.close();
                return true;
            }
        }
    }
</script>

<style scoped>
  .container  {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .container .row {
    margin: 0 auto;
  }
</style>
