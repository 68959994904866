<template>
  <CTable striped small fixed responsive="sm">
    <CTableHead color="info">
      <CTableRow>
        <CTableHeaderCell v-for="(field, index) in fields" :key="index">
          {{ field.label ? field.label : field.key  }}
        </CTableHeaderCell>
      </CTableRow>
    </CTableHead>
    <CTableBody>
      <CTableRow v-for="item in items" :key="item.id">
        <CTableDataCell v-for="(field, key) in fields" :key="key">
          {{ item[field.key] }}
        </CTableDataCell>
      </CTableRow>
    </CTableBody>
  </CTable>
</template>

<script>
export default {
  name: "CBTable",
  props: ['items', 'fields']
}
</script>

<style scoped>

</style>
