<template>
  <div v-if="enrollment.form_result" class="form-result">
    <div v-for="(form,key) in enrollment.form_result.form_category.forms" v-bind:key="key">
      <h5> {{ key + 1 }}&nbsp;.{{form.title}}</h5>
      <div v-for="(question,qKey) in form.form_questions" v-bind:key="qKey" class="form-group disabled" :aria-disabled="true">
        <label v-if="question.type === 'separator'" :for="qKey">{{ question.title }}</label>
        <label class="title" v-else :for="qKey"><strong>{{ question.title }}</strong></label>
        <template v-if="!question.answers.length && (question.type === 'typed' || question.type === 'email' || question.type === 'fio') && question.id !== anketaKey">
          <input class="form-control" :disabled="true" :required="question.is_required" type="text" :value="enrollment.form_result.answers[question.id]" />
        </template>
        <template v-if="question.type === 'separator'">
          <hr/>
        </template>
        <template v-if="question.type === 'typed_long'">
          <textarea v-model="answerForm[question.id]" :disabled="true" :required="question.is_required"></textarea>
        </template>
        <template v-if="question.id === anketaKey">
          <b-row style="margin-left: 40px;" v-for="(answer, key) in enrollment.form_result.answers[anketaKey]" v-bind:key="key">
            <b-col size="md-6">
              <div class="form-group">
                <label>Страна</label>
                <input class="form-control" :disabled="true" :required="true" type="text" :value="answer.country.originalObject.country_name_ru" />
              </div>
            </b-col>
            <b-col size="md-6">
              <div class="form-group">
                <label>Код ТН ВЭД</label>
                <input class="form-control" :disabled="true" :required="true" type="text" :value="answer.code" />
              </div>
            </b-col>
          </b-row>
        </template>
        <template v-if="question.type !== 'separator' && question.answers.length && question.id !== anketaKey">
          <div class="block-form-control-wrapper">
            <div class="form-wrapper">
              <div v-if="question.answers.length" v-for="(answer, kAnswer) in question.answers" :key="kAnswer" class="radio">
                <label :for="answer.id" class="for-radio">
                  <input :disabled="true" type="radio" :id="answer.id" v-model="enrollment.form_result.answers[question.id]" :value="answer.title" :required="kAnswer == 0" :name="question.id" class="ch-settings">
                  &nbsp;
                  <span class="text" v-html="answer.title"></span>
                </label>
              </div>
            </div>
            <br/>
            <p class="text-blue"><i>{{question.description}}</i></p>
          </div>
        </template>
        <hr/>

      </div>
    </div>
    <div id="print">
      <h1>Отчет об оценке экспортной зрелости</h1>
      <div v-for="(form,key) in enrollment.form_result.form_category.forms" v-bind:key="key">
        <h5> {{ key + 1 }}&nbsp;.{{form.title}}</h5>
        <div v-for="(question,qKey) in form.form_questions" v-bind:key="qKey" class="form-group disabled" :aria-disabled="true">
          <strong  v-if="question.type === 'separator'" :for="qKey"><strong>{{ question.title }}</strong></strong>
          <strong v-else :for="qKey">{{ question.title }}</strong>
          <template v-if="!question.answers.length && question.id !== anketaKey">
            {{enrollment.form_result.answers[question.id]}}
          </template>
          <template v-if="question.type === 'separator'">
            <hr/>
          </template>
          <template v-if="question.id === anketaKey">
            <table>
              <thead>
              <tr>
                <th >Страна</th>
                <th>Код ТН ВЭД</th>
              </tr>
              </thead>
              <tbody>
                <tr v-for="(answer, key) in enrollment.form_result.answers[anketaKey]">
                  <td>{{answer.country.originalObject.country_name_ru}}</td>
                  <td>{{answer.code}}</td>
                </tr>
              </tbody>
            </table>
          </template>
          <template v-if="question.type !== 'separator' && question.answers.length && question.id !== anketaKey">
            <div class="block-form-control-wrapper">
              <div class="form-wrapper">
                {{enrollment.form_result.answers[question.id]}}
              </div>
              <br/>
              <p class="text-blue"><i>{{question.description}}</i></p>
            </div>
          </template>
          <hr/>
        </div>
      </div>
      <div class="form-group">
        Итоговый балл по анкете: {{ enrollment.form_result.result.points }}<br>
        Категория компании: {{ enrollment.form_result.result.category }}
      </div>
      <hr/>
      Комментарий: {{ comment }}
      <hr/>
      Сотрудник: {{ user.fullname }}<br/>
      Подпись:
    </div>
  </div>
</template>

<script>
    import {TokenService} from "@/services/token.service";
    export default {
        name: "FormResult",
        props: ['enroll', 'comment'],
        data: () => {
            return {
              enrollment: {},
              user: TokenService.getUser(),
              boxOne: '',
              anketaKey: 128
            }
        },
        mounted() {
            this.enrollment = this.enroll;
            if(this.enroll.program && this.enroll.program.id === 2) {
              this.anketaKey = 3866
            }
        }
    }
</script>

<style scoped>
  #print{
    display: none;
  }
  .title {
    font-size: 16px;
  }
  input:checked + .text {
    text-decoration: underline;
    font-weight: bold;
    color: #323232;
  }
</style>
