<template>
  <div v-if="company">
    <div v-if="print">
      <p><b><span style="font-size:11.0pt"><span style="font-family:Arial">{{index}}. Экспресс-оценка и уточненная оценка финансового состояния (в баллах):</span></span></b></p>
      <table class="MsoTableGrid" style="border-collapse:collapse; border:solid windowtext 1.0pt">
        <tbody>
        <tr style="height:22.85pt">
          <td style="width:36.95pt; border:solid windowtext 1.0pt" width="37">
            <p align="center" style="text-align:center; padding:0cm 5.4pt 0cm 5.4pt"><span style="height:22.85pt"><b><span style="font-size:11.0pt;font-family:Arial;">№№</span></b></span></p>
          </td>
          <td style="width:274.95pt; border:solid windowtext 1.0pt; border-left:none" width="275">
            <p align="center" style="text-align:center; padding:0cm 5.4pt 0cm 5.4pt"><span style="height:22.85pt"><b><span style="font-size:11.0pt;font-family:Arial;">Параметр</span></b></span></p>
          </td>
          <td style="width:155.3pt; border:solid windowtext 1.0pt; border-left:none" width="155">
            <p align="center" style="text-align:center; padding:0cm 5.4pt 0cm 5.4pt"><span style="height:22.85pt"><b><span style="font-size:11.0pt;font-family:Arial;">Показатель</span></b></span></p>
          </td>
        </tr>
        <tr>
          <td style="width:36.95pt; border:solid windowtext 1.0pt; border-top:none" width="37">
            <ol>
              <li style="padding:0cm 5.4pt 0cm 5.4pt">&nbsp;</li>
            </ol>
          </td>
          <td style="width:274.95pt; border-top:none; border-left:none; border-bottom:solid windowtext 1.0pt; border-right:solid windowtext 1.0pt" width="275">
            <p style="padding:0cm 5.4pt 0cm 5.4pt"><span style="font-size:11.0pt;font-family:Arial;">Экспресс-оценка финансового состояния компании (в баллах)</span></p>

            <p style="padding:0cm 5.4pt 0cm 5.4pt">&nbsp;</p>
          </td>
          <td style="width:155.3pt; border-top:none; border-left:none; border-bottom:solid windowtext 1.0pt; border-right:solid windowtext 1.0pt" width="155">
            <p align="center" style="text-align:center; padding:0cm 5.4pt 0cm 5.4pt">
                <span v-if="company.enroll.reports.finance_express_report && company.enroll.reports.finance_express_report.report_data" style="font-size:11.0pt;font-family:Arial;">
                  <span class="badge" :class="'badge-' + company.enroll.reports.finance_express_report.status">
                    {{ company.enroll.reports.finance_express_report.report_data.total ? company.enroll.reports.finance_express_report.report_data.total : company.enroll.reports.finance_express_report.status_crm }}
                  </span>
                </span>
            </p>
          </td>
        </tr>
        <tr>
          <td style="width:36.95pt; border:solid windowtext 1.0pt; border-top:none" width="37">
            <ol start="2">
              <li style="padding:0cm 5.4pt 0cm 5.4pt;font-family:Arial;font-size:11.0pt">&nbsp;</li>
            </ol>
          </td>
          <td style="width:274.95pt; border-top:none; border-left:none; border-bottom:solid windowtext 1.0pt; border-right:solid windowtext 1.0pt" width="275">
            <p style="padding:0cm 5.4pt 0cm 5.4pt"><span style="font-family:Arial;font-size:11.0pt">Уточненная оценка финансового состояния компании (в баллах)</span></p>
          </td>
          <td style="width:155.3pt; border-top:none; border-left:none; border-bottom:solid windowtext 1.0pt; border-right:solid windowtext 1.0pt" width="155">
            <p v-if="company.enroll.reports && company.enroll.reports.finance_report && company.enroll.reports.finance_report.report_data" align="center" style="text-align:center; padding:0cm 5.4pt 0cm 5.4pt"><span style="font-family:Arial;font-size:11.0pt">{{ company.enroll.reports.finance_report.report_data.total }}</span></p>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-else>
      <h5>{{index}}.  Экспресс-оценка и уточненная оценка финансового состояния (в баллах):</h5>
      <table class="table table-striped table-bordered">
        <thead>
        <tr>
          <th>Параметр</th>
          <th>Показатель</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>
            Экспресс-оценка финансового состояния компании (в баллах)
          </td>
          <td v-if="company.enroll.reports && company.enroll.reports.finance_express_report && company.enroll.reports.finance_express_report.report_data" :class="'bg-' + company.enroll.reports.finance_express_report.status" class="font-weight-bold">
            <span>
              {{ company.enroll.reports.finance_express_report.report_data.total ? company.enroll.reports.finance_express_report.report_data.total : company.enroll.reports.finance_express_report.status_crm }}
            </span>
          </td>
          <td v-else>
            -
          </td>
        </tr>
        <tr>
          <td>
            Уточненная оценка финансового состояния компании (в баллах)
          </td>
          <td v-if="company.enroll.reports.finance_report && company.enroll.reports.finance_report.report_data" class="font-weight-bold" :class="'bg-' + company.enroll.reports.finance_report.status">
            {{ company.enroll.reports.finance_report.report_data.total }}
          </td>
          <td v-else>
            -
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
    export default {
        name: "FinanceReport",
        props: ['company', 'print', 'index']
    }
</script>

<style scoped>

</style>
