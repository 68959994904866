<template>
  <div v-if="report" class="animated fadeIn">
    <CRow>
      <CCol sm="12">
        <CRow>
          <CCol md="12">
            <CForm class="form-result" @submit.prevent="updateReport({isSend: true, comment: report.comment, reportData: {comment: report.report_data.comment}})">
              <CCard>
                <CCardHeader>
                  <router-link :to="'/company/' + id + '?tab=6'">←	 Назад к диагностике компании &laquo;<small>{{company.name}}</small>&raquo;</router-link>
                </CCardHeader>
                <CCardBody :disabled="report.is_send">
<!--                  <CRow>
                    <CCol lg="6" sm="12" md="12">
                      <div>
                        <QuestReport v-if="question" :comment="question.answers.comment" :event-date="company.event_date" />
                      </div>
                    </CCol>
                  </CRow>
                  <hr/>-->
                  <CRow>
                    <CCol lg="6" sm="12" md="12">
                      <div>
                        <FormReport :company="company"/>
                      </div>
                    </CCol>
                  </CRow>
                  <hr/>
                  <CRow>
                    <CCol lg="6" sm="12" md="12">
                      <div>
                        <FactorsReport :company="company"/>
                      </div>
                    </CCol>
                  </CRow>
                  <hr/>
                  <CRow>
                    <CCol lg="6" sm="12" md="12">
                      <div>
                        <InterviewReport :index="3" :company="company"/>
                      </div>
                    </CCol>
                  </CRow>
                  <hr/>
                  <CRow>
                    <CCol lg="6" sm="12" md="12">
                      <div>
                        <FinanceReport :index="4" :company="company"/>
                      </div>
                    </CCol>
                  </CRow>
                </CCardBody>
                <CCardFooter>
                  <CButtonGroup  v-if="report.is_send && !isMentor" class="float-start">
                    <CButton color="success" :disabled="report.approved_status === 'approved'" @click="updateReport({approvedStatus: 'approved'})" size="sm" class="text-white">
                      <i class="fa fa-check"></i>
                      Утвердить отчет
                    </CButton>
                    <CButton color="danger" :disabled="report.approved_status === 'rejected'" @click="updateReport({approvedStatus: 'rejected'})" size="sm" class="text-white">
                      Отклонить отчет
                    </CButton>
                  </CButtonGroup>
                  <CButtonGroup class="float-end">
                    <CButton v-if="report.is_send" color="primary" @click="print" size="sm" class="text-white">
                      <i class="fa fa-print"></i>
                      Распечатать
                    </CButton>
                    <a v-if="report.report && report.is_send" target="_blank" class="btn btn-success btn-sm" :href="report.report.file">
                      <i class="fa fa-download"></i>&nbsp;Скачать отчет
                    </a>
                    <CButton v-if="report.report && report.is_send" @click="confirmUpdateReport({report: false})" color="danger" size="sm" class="text-white">
                      <i class="fa fa-trash"></i>
                      Удалить отчет
                    </CButton>
                    <button v-if="!report.is_send" type="submit" class="btn btn-success btn-sm">
                      <i class="fa fa-dot-circle-o"></i>
                      Сохранить
                    </button>
                    <file-upload
                      ref="upload"
                      :class="!$refs.upload || !$refs.upload.active ? 'btn btn-sm btn-warning' : 'btn btn-sm btn-secondary'"
                      :post-action="postAction"
                      :headers="headers"
                      :data="{context: 'default'}"
                      name="binaryContent"
                      @input-file="inputFile"
                      v-if="report.is_send && !report.report"
                    >
                      <i class="fa fa-upload"></i>
                      {{$refs.upload && $refs.upload.active ? 'Загрузка ...' : 'Загрузить отчет'}}
                    </file-upload>
                  </CButtonGroup>
                </CCardFooter>
              </CCard>
            </CForm>
          </CCol>
        </CRow>
        <div id="print">
          <p><b><span style="font-size:12.0pt"><span style="font-family:Arial">Итоговый сводный отчет комплексной диагностики компании</span></span></b></p>
          <p><b><span style="font-size:11.0pt"><span style="font-family:Arial">Наименование компании:</span></span></b> <span style="font-family:Arial">&laquo;{{company.name}}&raquo; (ОГРН {{ company.ogrn }})</span></p>
          <FormReport :print="true" :company="company"/>
          <FactorsReport :print="true" :company="company"/>
          <InterviewReport :index="3" :print="true" :company="company"/>
          <FinanceReport :index="4" :print="true" :company="company"/>
          <p style="font-size:11.0pt;font-family:Arial;"><strong>Дата:</strong> {{ report.modified_at }}</p>
          <p style="font-size:11.0pt;font-family:Arial;"><strong>ФИО:</strong> {{ user.fullname }}</p>
          <p style="font-size:11.0pt;font-family:Arial;"><strong>Подпись:</strong></p>
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
    import {TokenService} from "@/services/token.service";
    import QuestReport from "./reports/QuestReport";
    import FormReport from "./reports/FormReport";
    import FactorsReport from "./reports/FactorsReport";
    import InterviewReport from "./reports/InterviewReport";
    import FinanceReport from "./reports/FinanceReport";
    import FileUpload from 'vue-upload-component';
    import { saveAs } from 'file-saver';
    //import htmlDocx from 'html-docx-js/dist/html-docx';
    import ApiService from "@/services/api.service";
    export default {
        name: "DiagnostFinal",
        components: {FinanceReport, InterviewReport, FactorsReport, FormReport, QuestReport, FileUpload},
        data() {
            return {
                id: null,
                company: null,
                isMentor: TokenService.isMentor(),
                report: null,
                question: null,
                user: TokenService.getUser(),
                postAction: process.env.VUE_APP_ROOT_API + 'api/attaches',
                headers: TokenService.getHeaders()
            }
        },
        mounted() {
            this.id = this.$route.params.id;
            ApiService
                .get('api/mentoring/enrolls/' + this.id + '/company')
                .then((res) => {
                    this.company = res.data;
                    this.report = this.company.enroll.reports.final_report;
                    if(!this.report.report_data) {
                        this.report.report_data = {
                            comment: null
                        }
                    }
                })
        },
        methods: {
            confirmUpdateReport(params) {
              this.updateReport(params);
     /*           this.$bvModal.msgBoxConfirm('Вы уверены, что хотите удалить загруженный отчет?', {
                    title: 'Подтвердите действие:',
                    okVariant: 'danger',
                    okTitle: 'Да',
                    cancelTitle: 'Нет',
                    hideHeaderClose: false,
                    centered: true
                }).then(res => {
                    if(res) {

                    }
                })*/
            },
            updateReport(params) {

              console.log(this.report)
              return ApiService
                .put('api/mentoring/enrollreports/' + this.report.id, params)
                .then(res => {
                  this.report = new Proxy(res.data, {});
                  this.factors = res.data.report_data;
                  this.$toast.show('Изменения сохранены', {type: 'success'});
                })

                /*this.$bvModal.msgBoxConfirm('Внимание! Сохранение итогового отчета производится после завершения комплексной диагностики компании. Все этапы диагностики в CRM будут закрыты и сохранены!', {
                    title: 'Подтвердите действие:',
                    okVariant: 'danger',
                    okTitle: 'Да',
                    cancelTitle: 'Нет',
                    hideHeaderClose: false,
                    centered: true
                })
                .then((res) => {
                    if(res) {

                    }
                  }
                );*/
            },
            saveToDoc() {

                let doc = document.implementation.createHTMLDocument('Report');
                doc.write('<html><head>');
                doc.write('<meta http-equiv="Content-Type" content="text/html; charset=UTF-8">');
                doc.write('<style>@page{size: portait;}body{margin: 10px 10px 10px 20px;}.dont-break-out {\n' +
                    '\n' +
                    '  /* These are technically the same, but use both */\n' +
                    '  overflow-wrap: break-word;\n' +
                    '  word-wrap: break-word;\n' +
                    '\n' +
                    '  -ms-word-break: break-all;\n' +
                    '  /* This is the dangerous one in WebKit, as it breaks things wherever */\n' +
                    '  word-break: break-all;\n' +
                    '  /* Instead use this non-standard one: */\n' +
                    '  word-break: break-word;\n' +
                    '\n' +
                    '  /* Adds a hyphen where the word breaks, if supported (No Blink) */\n' +
                    '  -ms-hyphens: auto;\n' +
                    '  -moz-hyphens: auto;\n' +
                    '  -webkit-hyphens: auto;\n' +
                    '  hyphens: auto;\n' +
                    '\n' +
                    '}</style>');
                doc.write('</head><body>');
                doc.write(document.getElementById('print').innerHTML);
                doc.write('</body></html>');



                /*let blob = htmlDocx.asBlob(doc.documentElement.innerHTML);*/
                saveAs('', "detailed_report.docx");
            },
            inputFile: function (newFile, oldFile) {

                if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
                    if (!this.$refs.upload.active) {
                        this.$refs.upload.active = true
                    }
                }

                if (newFile && oldFile && !newFile.active && oldFile.active) {
                    // Get response data
                    if(newFile.response.media) {
                        this.updateReport({
                            'report': newFile.response.media.id
                        }, false)
                    }
                }
            },
            print() {
              console.log('printer')
                let block = window.open('', 'PRINT');
                block.document.write('<html><head>');
                block.document.write('<style>@page{size: portait;}body{margin: 10px 10px 10px 20px;}.dont-break-out {\n' +
                    '\n' +
                    '  /* These are technically the same, but use both */\n' +
                    '  overflow-wrap: break-word;\n' +
                    '  word-wrap: break-word;\n' +
                    '\n' +
                    '  -ms-word-break: break-all;\n' +
                    '  /* This is the dangerous one in WebKit, as it breaks things wherever */\n' +
                    '  word-break: break-all;\n' +
                    '  /* Instead use this non-standard one: */\n' +
                    '  word-break: break-word;\n' +
                    '\n' +
                    '  /* Adds a hyphen where the word breaks, if supported (No Blink) */\n' +
                    '  -ms-hyphens: auto;\n' +
                    '  -moz-hyphens: auto;\n' +
                    '  -webkit-hyphens: auto;\n' +
                    '  hyphens: auto;\n' +
                    '\n' +
                    '}</style>');
                block.document.write('</head><body>');
                block.document.write(document.getElementById('print').innerHTML);
                block.document.write('</body></html>');
                block.document.close();
                block.focus();
                block.print();
                block.close();
                return true;
            }
        }
    }
</script>

<style scoped>
  #print {
    display: none;
  }
  .mw-50 {
    max-width: 50%;
  }
  .btn-wrapper {
    width: 100%;
  }
</style>
