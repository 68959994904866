<template>
  <div v-if="company && company.enroll && company.enroll.reports">
    <CRow class="mt-4">
      <CCol md="4">
        <CCard class="card-fixed-height">
          <CCardHeader>
            <strong>Экспресс-оценка финансового состояния</strong>
          </CCardHeader>
          <CCardBody>
            <ul class="list-group">
              <li v-if="!company.enroll.reports.finance_express_report" class="list-group-item py-2 disabled">
                Этап в данный момент недоступен
              </li>
              <li v-if="company.enroll.reports.finance_express_report" class="list-group-item py-2" :class="'list-group-item-' + company.enroll.reports.finance_express_report.status">
                {{ company.enroll.reports.finance_express_report.status_crm }}
              </li>
            </ul>
          </CCardBody>
          <CCardFooter>
            <CButton v-if="user.roles.indexOf('ROLE_SUPER_ADMIN') > -1" class="pull-left" color="info" size="sm" @click="revertReport(company.enroll.reports.finance_express_report.id)"><i class="fa fa-refresh"></i></CButton>
            <a :href="'/company/' + id + '?tab=4'" class="btn float-end btn-success btn-sm" :disabled="!company.enroll.reports.finance_express_report">
              <i class="fa fa-eye"></i>
              Перейти к оценке
            </a>
          </CCardFooter>
        </CCard>
      </CCol>
      <CCol md="4">
        <CCard class="card-fixed-height">
          <CCardHeader>
            <strong>Аудит на предмет стоп-факторов</strong>
          </CCardHeader>
          <CCardBody>
            <ul class="list-group">
              <li v-if="company.enroll.reports.stop_factors_report" class="list-group-item py-2" :class="'list-group-item-' + company.enroll.reports.stop_factors_report.status">
                {{ company.enroll.reports.stop_factors_report.status_crm }}
              </li>
              <li v-if="!company.enroll.reports.stop_factors_report" class="list-group-item py-2 disabled">
                Этап в данный момент недоступен
              </li>
            </ul>
          </CCardBody>
          <CCardFooter>
            <CButton v-if="user.roles.indexOf('ROLE_SUPER_ADMIN') > -1" class="pull-left" color="info" size="sm" @click="revertReport(company.enroll.reports.stop_factors_report.id)"><i class="fa fa-refresh"></i></CButton>
            <a :disabled="!company.enroll.reports.stop_factors_report" :href="'/company/' + id + '?tab=5'" class="btn float-end btn-success btn-sm">
              <i class="fa fa-eye"></i>
              Перейти к аудиту
            </a>
          </CCardFooter>
        </CCard>
      </CCol>
      <CCol md="4">
        <CCard class="card-fixed-height">
          <CCardHeader>
            <strong>Оценка экспортной зрелости</strong><br/>
          </CCardHeader>
          <CCardBody>
            <ul v-if="company.enroll.reports.form_report" class="list-group">
              <li class="list-group-item py-2" :class="'list-group-item-' + company.enroll.reports.form_report.status">
                {{ company.enroll.reports.form_report.status_crm }}
              </li>
            </ul>
            <p><span v-if="company.enroll.reports.form_report" :class="'badge badge-' + company.enroll.reports.form_report.status + ' text-white'"></span></p>
            <h5 v-if="company.enroll.form_result">
              <CBadge color="primary">Балл {{company.enroll.form_result.result.points}}</CBadge>&nbsp;
              <CBadge color="warning">{{company.enroll.form_result.result.category}}</CBadge>
            </h5>
          </CCardBody>
          <CCardFooter>
            <CButton v-if="user.roles.indexOf('ROLE_SUPER_ADMIN') > -1" class="pull-left" color="info" size="sm" @click="revertReport(company.enroll.reports.form_report.id)"><i class="fa fa-refresh"></i></CButton>
            <router-link :to="'/diagnost/' + company.id + '/form'">
              <CButton :disabled="!company.enroll.form_result" class="float-end btn btn-success btn-sm">
                <i class="fa fa-eye"></i>
                Посмотреть результаты
              </CButton>
            </router-link>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
    <CRow class="mt-4">
      <CCol md="4">
        <CCard class="card-fixed-height">
          <CCardHeader>
            <strong>Верификация анкеты экспортной зрелости</strong>&nbsp;
          </CCardHeader>
          <CCardBody>
            <ul class="list-group">
              <li v-if="!company.enroll.reports.verify_form_report" class="list-group-item py-2 disabled">
                Этап в данный момент недоступен
              </li>
              <li v-if="company.enroll.reports.verify_form_report" class="list-group-item py-2" :class="'list-group-item-' + company.enroll.reports.verify_form_report.status">
                {{ company.enroll.reports.verify_form_report.status_crm }}
              </li>
            </ul>
            <br/>
            <h5 v-if="company.enroll.reports.verify_form_report && company.enroll.reports.verify_form_report.report_data">
              <span class="badge bg-primary">Балл {{company.enroll.reports.verify_form_report.report_data.points}}</span>&nbsp;
              <span class="badge bg-warning text-white">{{company.enroll.reports.verify_form_report.report_data.category}}</span>
            </h5>
          </CCardBody>
          <CCardFooter>
            <CButton v-if="user.roles.indexOf('ROLE_SUPER_ADMIN') > -1" class="pull-left" color="info" size="sm" @click="revertReport(company.enroll.reports.verify_form_report.id)"><i class="fa fa-refresh"></i></CButton>
            <router-link :to="'/diagnost/' + id + '/verify'">
              <CButton class="btn float-end btn-success btn-sm" :disabled="!company.enroll.reports.verify_form_report">
                <i class="fa fa-eye"></i>
                Перейти к оценке
              </CButton>
            </router-link>
          </CCardFooter>
        </CCard>
      </CCol>
      <CCol md="4">
        <CCard class="card-fixed-height">
          <CCardHeader>
            <strong>Структурированное интервью с представителями компании</strong>&nbsp;
          </CCardHeader>
          <CCardBody>
            <ul class="list-group">
              <li v-if="!company.enroll.reports.interview_report" class="list-group-item py-2 disabled">
                Этап в данный момент недоступен
              </li>
              <li v-if="company.enroll.reports.interview_report" class="list-group-item py-2" :class="'list-group-item-' + company.enroll.reports.interview_report.status">
                {{ company.enroll.reports.interview_report.status_crm }}
              </li>
            </ul>
            <br/>
            <h5 v-if="company.enroll.reports.interview_report && company.enroll.reports.interview_report.report_data">
              <span class="badge bg-primary">Балл {{company.enroll.reports.interview_report.report_data.points}}</span>&nbsp;
            </h5>
          </CCardBody>
          <CCardFooter>
            <CButton v-if="user.roles.indexOf('ROLE_SUPER_ADMIN') > -1" class="pull-left" color="info" size="sm" @click="revertReport(company.enroll.reports.interview_report.id)"><i class="fa fa-refresh"></i></CButton>
            <router-link :to="'/diagnost/' + id + '/interview'">
              <CButton class="btn float-end btn-success btn-sm" :disabled="!company.enroll.reports.interview_report" >
                <i class="fa fa-eye"></i>
                Перейти к оценке
              </CButton>
            </router-link>
          </CCardFooter>
        </CCard>
      </CCol>
      <CCol md="4">
        <CCard class="card-fixed-height">
          <CCardHeader>
            <strong>Итоговый отчет</strong>
          </CCardHeader>
          <CCardBody>
            <ul class="list-group">
              <li v-if="!company.enroll.reports.final_report" class="list-group-item py-2 disabled">
                Этап в данный момент недоступен
              </li>
              <li v-if="company.enroll.reports.final_report" class="list-group-item py-2" :class="'list-group-item-' + company.enroll.reports.final_report.status">
                {{ company.enroll.reports.final_report.status_crm }}
              </li>
            </ul>
          </CCardBody>
          <CCardFooter>
            <CButton v-if="user.roles.indexOf('ROLE_SUPER_ADMIN') > -1" class="pull-left" color="info" size="sm" @click="revertReport(company.enroll.reports.final_report.id)">
              <i class="fa fa-refresh"></i>
            </CButton>
            <router-link :disabled="!company.enroll.reports.final_report" :to="'/diagnost/' + id + '/final'">
              <CButton class="float-end btn-success btn-sm">
                <i class="fa fa-eye"></i>
                Посмотреть отчет
              </CButton>
            </router-link>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
    import ApiService from "@/services/api.service";
    import {TokenService} from "@/services/token.service";
    export default {
      name: "Diagnost",
      props: ['data'],
      data: () => {
        return {
          user: TokenService.getUser(),
          id: null,
          company: null,
          labelTxt: {
            dataOn: 'да',
            dataOff: 'нет'
          }
        }
      },
      mounted() {
      this.company = this.data
          this.id = this.data.id;
          ApiService
              .get('api/mentoring/enrolls/' + this.id + '/company')
              .then((res) => {
                  this.company = res.data;
              })
          ;
      },
      methods: {
        confirmUpdate(params) {
            this.$bvModal.msgBoxConfirm('Вы уверены, что необходимо удалить этот документ?', {
                title: 'Подтвердите действие:',
                okVariant: 'danger',
                okTitle: 'Да',
                cancelTitle: 'Нет',
                hideHeaderClose: false,
                centered: true
            })
                .then((res) => {
                        if(res) {
                            this.updateEnroll(params)
                        }
                })
        },
        revertReport(id) {
          let result = confirm("Вы уверены, что хотите удалить итоги прохождения этапа и перезапустить его?");
          if(result) {
            ApiService
              .post('api/mentoring/enrollreports/' + id + '/reverts')
              .then((res) => {
                this.company.enroll = res.data;
              })
          }
        },
        updateEnroll(params) {
            ApiService
                .put('api/mentoring/enrolls/' + this.company.enroll.id, params)
                .then((res) => {
                    this.company.enroll = res.data;
                })
        }
      },
      computed: {
          isAddDoc: function() {
              return this.company ? this.company.enroll.is_add_doc : null;
          },
      },
      watch: {
          isAddDoc: {
              handler: function(newVal, oldVal) {
                  if(oldVal !== null && newVal !== oldVal) {
                      this.updateEnroll({
                          isAddDoc: newVal
                      })
                  }
              }
          }
      }
    }
</script>

<style scoped>
    ol li {
        margin-bottom: 20px;
        vertical-align: middle;
    }
  .badge-secondary {
    background: #323232;
  }
  .hidden {
    visibility: hidden;
  }
  .card-fixed-height {
    min-height: 250px!important;
  }
</style>
