<template>
  <div v-if="company && factors" id="print">
    <div style="margin: 20px">
    <p><b><span style="font-size:12pt"><span style="font-family:Arial">Отчет о проведении аудита компании на предмет наличия/отсутствия стоп-факторов для осуществления экспорта</span></span></b></p>

    <p><b><span style="font-size:11pt"><span style="font-family:Arial">Наименование компании: </span></span></b><span style="font-size:11pt"><span style="font-family:Arial">{{company.name}} (ОГРН: {{company.ogrn}})</span></span></p>

    <table class="Table" style="width:484.15pt; margin-left:4.65pt; border-collapse:collapse; border:none" width="484" v-for="(factor, key) in factors" :key="key">
      <tbody>
      <tr style="height:25.9pt">
        <td colspan="4" nowrap="nowrap" style="width:484.15pt; border:solid windowtext 1.0pt" width="484">
          <p style="padding:0cm 5.4pt 0cm 5.4pt"><span style="height:25.9pt"><b><span style="font-size:12pt"><span style="font-family:Arial"><span style="color:black">Страна: {{factor.country.originalObject.country_name_ru}}</span></span></span></b></span></p>
        </td>
      </tr>
      <tr style="height:42.6pt">
        <td colspan="4" nowrap="nowrap" style="width:484.15pt; border:solid windowtext 1.0pt; border-top:none" width="484">
          <p style="padding:0cm 5.4pt 0cm 5.4pt"><span style="height:42.6pt"><b><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:black">Наименование товара: </span></span></span></b><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:black">{{factor.product}}</span></span></span></span></p>
        </td>
      </tr>
      <tr style="height:19.8pt">
        <td colspan="4" nowrap="nowrap" style="width:484.15pt; border:solid windowtext 1.0pt; border-top:none" width="484">
          <p style="padding:0cm 5.4pt 0cm 5.4pt"><span style="height:19.8pt"><b><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:black">Код ТН ВЭД: </span></span></span></b><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:black">{{factor.code}}</span></span></span></span></p>
        </td>
      </tr>
      <tr style="height:27.05pt">
        <td colspan="3" nowrap="nowrap" style="width:328.25pt; border:solid windowtext 1.0pt; border-top:none" width="328">
          <p align="center" style="text-align:center; padding:0cm 5.4pt 0cm 5.4pt"><span style="height:27.05pt"><b><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:black">Барьер</span></span></span></b></span></p>
        </td>
        <td nowrap="nowrap" style="width:155.9pt; border-top:none; border-left:none; border-bottom:solid windowtext 1.0pt; border-right:solid windowtext 1.0pt" width="156">
          <p align="center" style="text-align:center; padding:0cm 5.4pt 0cm 5.4pt"><span style="height:27.05pt"><b><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:black">Оценка</span></span></span></b></span></p>
        </td>
      </tr>
      <tr v-for="(bar,i) in factor.bars" :key="i" style="height:14.5pt">
        <td nowrap="nowrap" style="width:29.95pt; border:solid windowtext 1.0pt; border-top:none" width="30">
          <ul style="list-style-type: none;">
            <li style="padding:0cm 5.4pt 0cm 5.4pt">{{ i + 1 }}.</li>
          </ul>
        </td>
        <td colspan="2" nowrap="nowrap" style="width:298.3pt; border-top:none; border-left:none; border-bottom:solid windowtext 1.0pt; border-right:solid windowtext 1.0pt" width="298" >
          <p style="padding:0cm 5.4pt 0cm 5.4pt"><span style="height:14.5pt"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:black">{{ bar.name }}</span></span></span></span></p>
        </td>
        <td nowrap="nowrap" style="width:155.9pt; border-top:none; border-left:none; border-bottom:solid windowtext 1.0pt; border-right:solid windowtext 1.0pt" width="156">
          <p style="margin-left:0cm; padding:0cm 5.4pt 0cm 5.4pt"><span style="height:14.5pt"><span style="font-size:11pt"><span style="font-family:Arial">{{colorsText[bar.status]}}</span></span></span></p>
        </td>
      </tr>
      <tr style="height:29.95pt">
        <td colspan="2" nowrap="nowrap" style="width:193.55pt; border:solid windowtext 1.0pt; border-top:none" width="194">
          <p style="margin-left:0cm; padding:0cm 5.4pt 0cm 5.4pt"><span style="height:29.95pt"><b><span style="font-size:11pt"><span style="font-family:Arial">Результат по данной стране</span></span></b></span></p>
        </td>
        <td colspan="2" nowrap="nowrap" style="width:290.6pt; border-top:none; border-left:none; border-bottom:solid windowtext 1.0pt; border-right:solid windowtext 1.0pt" width="291">
          <p style="margin-left:0cm; padding:0cm 5.4pt 0cm 5.4pt"><span style="height:29.95pt"><span style="font-size:11pt"><span style="font-family:Arial">{{colors[key].statusText}}</span></span></span></p>
        </td>
      </tr>
      <tr height="0">
        <td style="border:none" width="30">&nbsp;</td>
        <td style="border:none" width="164">&nbsp;</td>
        <td style="border:none" width="135">&nbsp;</td>
        <td style="border:none" width="156">&nbsp;</td>
      </tr>
      </tbody>
    </table>

    <p><b><span style="font-size:11pt"><span style="font-family:Arial">Сводный отчет &laquo;Заградительные барьеры и требования рынков&raquo; </span></span></b></p>

    <table class="MsoTableGrid" style="border-collapse:collapse; border:solid windowtext 1.0pt">
      <tbody>
      <tr>
        <td style="width:155.7pt; border:solid windowtext 1.0pt" width="156">
          <p align="center" style="text-align:center; padding:0cm 5.4pt 0cm 5.4pt"><b><span style="font-size:11pt"><span style="font-family:Arial">Страна/Товар</span></span></b></p>
        </td>
        <td style="width:4.0cm; border:solid windowtext 1.0pt; border-left:none" width="113">
          <p align="center" style="text-align:center; padding:0cm 5.4pt 0cm 5.4pt"><b><span style="font-size:11pt"><span style="font-family:Arial">Выявленные барьеры</span></span></b></p>
        </td>
        <td style="width:2.0cm; border:solid windowtext 1.0pt; border-left:none" width="57">
          <p align="center" style="text-align:center; padding:0cm 5.4pt 0cm 5.4pt"><b><span style="font-size:11pt"><span style="font-family:Arial">Кол-во</span></span></b></p>
        </td>
        <td style="width:169.75pt; border:solid windowtext 1.0pt; border-left:none" width="170">
          <p align="center" style="text-align:center; padding:0cm 5.4pt 0cm 5.4pt"><b><span style="font-size:11pt"><span style="font-family:Arial">Результат по стране</span></span></b></p>
        </td>
      </tr>
      <template  v-for="(factor,key) in factors">
        <tr>
          <td rowspan="3" style="width:155.7pt; border:solid windowtext 1.0pt; border-top:none" width="156">
            <p style="padding:0cm 5.4pt 0cm 5.4pt"><b><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:black">{{factor.country.originalObject.country_name_ru}}</span></span></span></b></p>

            <p style="padding:0cm 5.4pt 0cm 5.4pt">&nbsp;</p>

            <p style="padding:0cm 5.4pt 0cm 5.4pt"><span style="font-size:11pt"><span style="font-family:Arial"><span style="color:black">{{factor.product}}</span></span></span></p>

            <p style="padding:0cm 5.4pt 0cm 5.4pt">&nbsp;</p>
          </td>
          <td style="width:4.0cm; border-top:none; border-left:none; border-bottom:solid windowtext 1.0pt; border-right:solid windowtext 1.0pt" width="113">
            <p style="padding:0cm 5.4pt 0cm 5.4pt"><span style="font-size:11pt"><span style="font-family:Arial">{{ colorsText['red'] }}</span></span></p>

            <p style="padding:0cm 5.4pt 0cm 5.4pt">&nbsp;</p>
          </td>
          <td style="width:2.0cm; border-top:none; border-left:none; border-bottom:solid windowtext 1.0pt; border-right:solid windowtext 1.0pt" width="57">
            <p align="center" style="text-align:center; padding:0cm 5.4pt 0cm 5.4pt"><b><span style="font-size:11pt"><span style="font-family:Arial">{{ calcBar(factor).red }}</span></span></b></p>
          </td>
          <td rowspan="3" style="width:169.75pt; border-top:none; border-left:none; border-bottom:solid windowtext 1.0pt; border-right:solid windowtext 1.0pt" width="170">
            <p style="margin-left:0cm; padding:0cm 5.4pt 0cm 5.4pt"><span style="font-size:11pt"><span style="font-family:Arial">{{ colors[key].statusText }}</span></span></p>

            <p style="padding:0cm 5.4pt 0cm 5.4pt">&nbsp;</p>
          </td>
        </tr>
        <tr>
          <td style="width:4.0cm; border-top:none; border-left:none; border-bottom:solid windowtext 1.0pt; border-right:solid windowtext 1.0pt" width="113">
            <p style="padding:0cm 5.4pt 0cm 5.4pt"><span style="font-size:11pt"><span style="font-family:Arial">{{ colorsText['yellow'] }}</span></span></p>

            <p style="padding:0cm 5.4pt 0cm 5.4pt">&nbsp;</p>
          </td>
          <td style="width:2.0cm; border-top:none; border-left:none; border-bottom:solid windowtext 1.0pt; border-right:solid windowtext 1.0pt" width="57">
            <p align="center" style="text-align:center; padding:0cm 5.4pt 0cm 5.4pt"><b><span style="font-size:11pt"><span style="font-family:Arial">{{calcBar(factor).yellow}}</span></span></b></p>
          </td>
        </tr>
        <tr>
          <td style="width:4.0cm; border-top:none; border-left:none; border-bottom:solid windowtext 1.0pt; border-right:solid windowtext 1.0pt" width="113">
            <p style="padding:0cm 5.4pt 0cm 5.4pt"><span style="font-size:11pt"><span style="font-family:Arial">{{ colorsText['green'] }}</span></span></p>
          </td>
          <td style="width:2.0cm; border-top:none; border-left:none; border-bottom:solid windowtext 1.0pt; border-right:solid windowtext 1.0pt" width="57">
            <p align="center" style="text-align:center; padding:0cm 5.4pt 0cm 5.4pt"><b><span style="font-size:11pt"><span style="font-family:Arial">{{calcBar(factor).green}}</span></span></b></p>
          </td>
        </tr>
      </template>
      <tr style="height:20.55pt">
        <td style="width:155.7pt; border:solid windowtext 1.0pt; border-top:none" width="156">
          <p align="right" style="text-align:right; padding:0cm 5.4pt 0cm 5.4pt"><span style="height:20.55pt"><b><span style="font-size:11pt"><span style="font-family:Arial">Статус компании по итогам аудита</span></span></b></span></p>
        </td>
        <td colspan="3" style="width:339.85pt; border-top:none; border-left:none; border-bottom:solid windowtext 1.0pt; border-right:solid windowtext 1.0pt" width="340">
          <p style="margin-left:0cm; padding:0cm 5.4pt 0cm 5.4pt"><span style="height:20.55pt"><span style="font-size:11pt"><span style="font-family:Arial">{{report.status_crm}}</span></span></span></p>
        </td>
      </tr>
      </tbody>
    </table>
      <p><b><span style="font-size:11pt"><span style="font-family:Arial">Комментарий специалиста по итогам этапа диагностики:</span></span></b></p>
      <p><span style="font-size:11pt"><span style="font-family:Arial">{{ comment }}</span></span></p>
      <p><b><span style="font-size:11pt"><span style="font-family:Arial">ФИО: </span></span></b><span lang="EN-US" style="font-size:11pt"><span style="font-family:Arial">{{ user.fullname }}</span></span></p>
      <p><b><span style="font-size:11pt"><span style="font-family:Arial">Подпись:</span></span></b></p>
    </div>
  </div>
</template>

<script>
    import {TokenService} from "@/services/token.service";
    export default {
        name: "FactorsResult",
        props: ['factors', 'colors', 'comment', 'company', 'report'],
        data: () => {
            return {
                user: TokenService.getUser(),
                colorsText: {
                    red: 'Критичный фактор',
                    yellow: 'Фактор может вызвать сложности',
                    green: 'Фактор не является критичным',
                },
                statusText: {
                    'success': 'Выявлены некритичные барьеры / не выявлены барьеры',
                    'warning': 'Выявлены барьеры, которые могут вызвать сложности при экспорте',
                    'danger': 'Выявлены критичные заградительные барьеры или более 5, которые могут вызвать сложности при экспорте',
                }
            }
        },
        methods: {
            calcBar(factor) {
                return {
                    red: factor.bars.filter(bar => bar.status === 'red').length,
                    yellow: factor.bars.filter(bar => bar.status === 'yellow').length,
                    green: factor.bars.filter(bar => bar.status === 'green').length
                }
            }
        }
    }
</script>

<style scoped>
  #print {
    display: none;
  }
</style>
